<app-elearning-banner class="abc"></app-elearning-banner>
<div [ngClass]="{'stop-animation': stopAnimation}" class="book-a-demo"  mat-raised-button (click)="openDialog()">Book a Demo Class</div>
<div class="partner-area pt-100 pb-70 border-bottom">
   
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>

<div class="features-area pt-100 pb-70">
    <app-features-style-one></app-features-style-one>
</div>

<app-elearning-about></app-elearning-about>

<!-- <div class="courses-area ptb-100">
    <app-courses></app-courses>
</div> -->

<app-funfacts-feedback></app-funfacts-feedback>

<app-get-instant-courses></app-get-instant-courses>

<!-- <div class="blog-area ptb-100">
    <app-blog></app-blog>
</div> -->

<app-view-all-courses></app-view-all-courses>

<div class="premium-access-area pb-5">
    <app-premium-access></app-premium-access>
</div>