import { Component, OnInit } from '@angular/core';
import { VideoDetailsComponent } from 'src/app/components/common/video-details/video-details.component';
import { MatDialog } from '@angular/material/dialog';
import { OwlOptions} from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-elearning-about',
  templateUrl: './elearning-about.component.html',
  styleUrls: ['./elearning-about.component.scss']
})
export class ElearningAboutComponent implements OnInit {
  
  constructor(private dialog :MatDialog) { }

  ngOnInit(): void {
  }
  videos = [
    { src: 'assets/img/candidateVideo/five.mp4' },
    { src: 'assets/img/candidateVideo/third.mp4' },
    { src: 'assets/img/candidateVideo/first.mp4' },
    { src: 'assets/img/candidateVideo/fourth.mp4' },
  ];
  
  images=[
    {src:'assets/img/screenshots/th6.jpeg'},
    {src:'assets/img/screenshots/th7.jpeg'},
    {src:'assets/img/screenshots/th8.jpeg'},
    {src:'assets/img/screenshots/th9.jpeg'},
    {src:'assets/img/screenshots/th10.jpeg'},
    {src:'assets/img/screenshots/th11.jpeg'},
    {src:'assets/img/screenshots/th12.jpeg'},
    {src:'assets/img/screenshots/th13.jpeg'},
    {src: 'assets/img/screenshots/th1.jpeg'},
    {src: 'assets/img/screenshots/th2.jpeg'},
    {src: 'assets/img/screenshots/th3.jpeg'},
    {src:'assets/img/screenshots/th4.jpeg'},
    {src:'assets/img/screenshots/th5.jpeg'},
   
  ]

  courseSlides: OwlOptions = {
    items:3,
    loop:true,
    nav: true,
    dots: true,
    autoplayHoverPause:true,
    margin: 10,
     navText: ['<','>'],
     autoplay:false,
   responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };


  courseSlides2: OwlOptions = {
    items:4,
    loop:true,
    nav: true,
    dots: true,
    autoplayHoverPause:true,
    margin: 10,
     navText: ['<','>'],
     autoplay:true,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      768: {
        items: 4,
      },
      991:{
        items:4,
      },
      1200: {
        items: 4,
      },
    },
  }


  openVideoModal(videoSrc: string): void {
    this.dialog.open(VideoDetailsComponent, {
      width: '800px',
      data: { videoSrc },
    });
  }
}

