<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <img src="assets/img/codemind-banner.png" alt="codemind-banner" />
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Privacy Policy</li>
            </ul>
            <h2>Privacy Policy</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>

<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-812 col-md-12">
                <div class="privacy-policy-content">
                    <img src="assets/img/codemind-banner.png" alt="image" />
                    <p>
                        <i
                            >This Privacy Policy was last updated on January 1,
                            2020.</i
                        >
                    </p>
                    <h3>About Information Sharing</h3>
                    <p>
                        We may need to share your Personal Information and your
                        data to our affiliates, resellers, service providers and
                        business partners solely for the purpose of providing
                        CODEMIND Services to you. The purposes for which we may
                        disclose your Personal Information or data to our
                        service providers may include, but are not limited to,
                        data storage, database management, web analytics and
                        payment processing. These service providers are
                        authorized to use your Personal Information or data only
                        as necessary to provide these services to us. In such
                        cases CODEMIND will also ensure that such affiliates,
                        resellers, service providers and business partners
                        comply with this Privacy Policy Statement and adopt
                        appropriate confidentiality and security measures. We
                        will obtain your prior specific consent before we share
                        your Personal Information or data to any person outside
                        CODEMIND for any purpose that is not directly connected
                        with providing CODEMIND Services to you. We will share
                        your Personal Information with third parties only in the
                        ways that are described in this Privacy Policy
                        Statement. We do not sell your Personal Information to
                        third parties. We may share generic aggregated
                        demographic information not linked to any Personal
                        Information regarding visitors and users with our
                        business partners and advertisers. Please be aware that
                        laws in various jurisdictions in which we operate may
                        obligate us to disclose user information and the
                        contents of your user account to the local law
                        enforcement authorities under a legal process or an
                        enforceable government request. In addition, we may also
                        disclose Personal Information and contents of your user
                        account to law enforcement authorities if such
                        disclosure is determined to be necessary by CODEMIND in
                        our sole and absolute discretion for protecting the
                        safety of our users, employees, or the general public.
                        If CODEMIND is involved in a merger, acquisition, or
                        sale of all or a portion of its business or assets, you
                        will be notified via email and/or a prominent notice on
                        our website of any change in ownership or uses of your
                        Personal Information, as well as any choices you may
                        have regarding your Personal Information. Users who wish
                        to correct, update or remove any Personal Information
                        including those from a public forum, directory or
                        testimonial on our site may do so either by accessing
                        their user account or by contacting
                        https://codemindtechnology.com Such changes may take up
                        to couple of days to take effect. We respond to all
                        enquiries within 30 days.
                    </p>

                    <h3>Cookies</h3>
                    <p>
                        When you use or access the CODEMIND site we may use
                        small text files containing a string of alphanumeric
                        characters called cookies to improve overall Site
                        experience, among other things. A cookie is a piece of
                        data stored on the user's hard drive containing
                        information about the user. They will not contain any
                        executable software, malicious code or virus. Cookies do
                        not establish your personal identity. They just
                        establish the source of requests to our web servers.
                        When you use or access the CODEMIND site we may use
                        small text files containing a string of alphanumeric
                        characters called cookies to improve overall Site
                        experience, among other things. A cookie is a piece of
                        data stored on the user's hard drive containing
                        information about the user. They will not contain any
                        executable software, malicious code or virus. Cookies do
                        not establish your personal identity. They just
                        establish the source of requests to our web servers.
                        Cookies generally do not permit us to personally
                        identify you. CODEMIND may use both session cookies and
                        persistent cookies. A session cookie generally
                        disappears after you close your browser. A persistent
                        cookie remains after you close your browser and may be
                        used by your browser on subsequent visits to the
                        CODEMIND site or other web sites. Persistent cookies can
                        generally be removed by you. Please review your web
                        browser "Help" file to learn the proper way to modify
                        your cookie settings and to remove cookies.
                    </p>
                    <h3>Security of your Information</h3>
                    <p>
                        We adopt industry appropriate data collection, storage
                        and processing practices and security measures, as well
                        as physical security measures to protect against
                        unauthorized access, alteration, disclosure or
                        destruction of your Personal Information, username,
                        password, transaction information and data stored in
                        your user account. Access to your name and email address
                        is restricted to our employees who need to know such
                        information in connection with providing CODEMIND
                        application to you and are bound by confidentiality
                        obligations. We may employ procedural and technological
                        security measures that are reasonably designed to help
                        protect your personally identifiable information from
                        loss, unauthorized access, disclosure, alteration or
                        destruction. CODEMIND may use encryption, password
                        protection, and other security measures to help prevent
                        unauthorized access to your personally identifiable
                        information.
                    </p>
                    <h3>Data Retention</h3>
                    <p>
                        We will retain your information while providing services
                        to you. In the event of you cancelling account with us,
                        we guarantee to retain your data for your backup
                        purposes only for one month from cancellation date after
                        which we may delete and destroy it. However, we will
                        retain any data for as long as required to comply with
                        laws or enforce our rights.
                    </p>
                    <h3>Change in Privacy Policy</h3>
                    <p>
                        CODEMIND reserves the right to changes this Privacy
                        Policy at any time. All the changes to the policy will
                        be effective immediately after its publication.
                    </p>
                    <h3>Contact Information</h3>
                    <p>
                        We appreciate your valuable feedback on our privacy
                        policy. You can contact us at info@CODEMIND.com or Attn:
                        Admin Department – CODEMIND, CODEMIND
                        Technology,Flat.No-201, Ashtvinayak, Vinayak Colony,
                        Kirti Classic, Near Luxmi Chowk, Hinjewadi, Pune-411058
                    </p>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_insight">
                        <ul>
                            <li><a routerLink="/about-1">About Us</a></li>
                            <li><a routerLink="/contact">Contact Us</a></li>
                            <li>
                                <a routerLink="/purchase-guide"
                                    >Purchase Guide</a
                                >
                            </li>
                            <li class="active">
                                <a routerLink="/privacy-policy"
                                    >Privacy Policy</a
                                >
                            </li>
                            <li>
                                <a routerLink="/terms-of-service"
                                    >Terms of Service</a
                                >
                            </li>
                        </ul>
                    </section>
                    <section class="widget widget_recent_courses">
                        <h3 class="widget-title">Recent Courses</h3>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"
                                ><span
                                    class="fullimage cover bg1"
                                    role="img"
                                ></span
                            ></a>
                            <div class="info">
                                <span>$49.00</span>
                                <h4 class="title usmall">
                                    <a routerLink="/single-courses-1"
                                        >The Data Science Course 2020: Complete
                                        Data Science Bootcamp</a
                                    >
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"
                                ><span
                                    class="fullimage cover bg2"
                                    role="img"
                                ></span
                            ></a>
                            <div class="info">
                                <span>$59.00</span>
                                <h4 class="title usmall">
                                    <a routerLink="/single-courses-1"
                                        >Java Programming Masterclass for
                                        Software Developers</a
                                    >
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-courses-1" class="thumb"
                                ><span
                                    class="fullimage cover bg3"
                                    role="img"
                                ></span
                            ></a>
                            <div class="info">
                                <span>$69.00</span>
                                <h4 class="title usmall">
                                    <a routerLink="/single-courses-1"
                                        >Deep Learning A-Z™: Hands-On Artificial
                                        Neural Networks</a
                                    >
                                </h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </section>
                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Popular Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/"
                                >Business
                                <span class="tag-link-count"> (3)</span></a
                            >
                            <a routerLink="/"
                                >Design
                                <span class="tag-link-count"> (3)</span></a
                            >
                            <a routerLink="/"
                                >Braike
                                <span class="tag-link-count"> (2)</span></a
                            >
                            <a routerLink="/"
                                >Fashion
                                <span class="tag-link-count"> (2)</span></a
                            >
                            <a routerLink="/"
                                >Travel
                                <span class="tag-link-count"> (1)</span></a
                            >
                            <a routerLink="/"
                                >Smart
                                <span class="tag-link-count"> (1)</span></a
                            >
                            <a routerLink="/"
                                >Marketing
                                <span class="tag-link-count"> (1)</span></a
                            >
                            <a routerLink="/"
                                >Tips
                                <span class="tag-link-count"> (2)</span></a
                            >
                        </div>
                    </section>
                </aside>
            </div> -->
        </div>
    </div>
</div>
