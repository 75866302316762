<!--<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
         
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>-->

<!--<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6" *ngFor="let img of descImages">
                            <div class="image wow fadeInLeft">
                                <img [src]="img?.image" [alt]="img?.name" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Distance learning</span>
                    <h2>
                        89% of people learning for professional development
                        report career benefits like getting a promotion,
                        starting a new career
                    </h2>
                    <p>
                        we take pride in the impact our professional development programs have on our learners' careers.
                        In fact, 89% of our learners have reported significant career benefits, such as securing
                        promotions or successfully transitioning to new careers.
                        <br>
                        Our industry-relevant curriculum,
                        practical hands-on projects, and expert guidance empower individuals to enhance their skills and
                        achieve their professional goals. Join <b>CODEMIND Technology</b> Pune and unlock new career
                        opportunities today.
                    </p>
                    <ul class="features-list">
                        <li>
                            <span><i class="flaticon-experience"></i> Expert
                                Instruction</span>
                        </li>
                        <li>
                            <span><i class="flaticon-time-left"></i> Lifetime
                                Acces</span>
                        </li>
                        <li>
                            <span><i class="flaticon-tutorials"></i> Remote
                                Learning</span>
                        </li>
                        <li>
                            <span><i class="flaticon-self-growth"></i> Self
                                Development</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>-->
<!-- <div class="shape1"><img src="assets/img/shape1.png" alt="image" /></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image" /></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image" /></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image" /></div> -->
<!--</div>-->



<div class="section-title-page"
    style="background-image: linear-gradient(to bottom,#eae9e1c9,#eae9e1c9),url('https://hyperbeans.com/assets/images/about/about_banner.jpg');">
    <div class="front">
        <h1>About Us</h1>
        <ol class="breadcrumb">
            <div class="container-fluid">
                <li class="breadcrumb-item breadcrumb-item">
                    <a routerLink="/">Home</a>
                </li>
                <li class="breadcrumb-item breadcrumb-item active"> About Us</li>
            </div>
        </ol>
    </div>
</div>
<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image wow fadeInLeft">
                                <img src="assets/img/codemind-banner.png" alt="codemind-banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="col-xl-4 col-lg-4">
                        <div class="project__area__details-right">
                            <div class="project__area__details-right-item mb-30">
                                <div class="project__area__details-right-item-details">
                                    <h3 class="mb-20">Our Vision</h3>
                                    <p>
                                        We Codemind Technology turns dreams into reality. We are not just the training
                                        institute but also a software development company;
                                        we are the architects of tomorrow's software development industry.
                                        We Codemind Technology is the perfect gateway to Professional growth, Real-World
                                        Experience and direct Job opportunities with us.
                                        Our Vision We believe that every dream, every vision, can be transformed into a
                                        reality that shapes the future.
                                        Our vision is to be the catalyst that bridges the gap between aspiration and
                                        achievement in the dynamic world of software development.</p>

                                </div>
                            </div>
                            <div class="project__area__details-right-item">
                                <div class="project__area__details-right-item-details-help">
                                    <h3 class="mb-20">Our Mission</h3>
                                    <p class="mb-20">Empowering Minds, Shaping Features Our mission is to cultivate a
                                        dynamic learning environment where innovation, creativity,
                                        and technology converge. We aspire to empower individuals with the knowledge,
                                        skills, and mindset necessary to thrive in the ever-evolving landscape of
                                        software development.</p>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>




<app-funfacts-feedback></app-funfacts-feedback>

<div class="features-area pb-70">
    <app-features-style-one></app-features-style-one>
</div>

<app-instructor></app-instructor>

<div class="premium-access-area bg-f9f9f9 ptb-100">
    <app-premium-access></app-premium-access>
    <!-- <div class="shape3"><img src="assets/img/shape3.png" alt="image" /></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image" /></div>
    <div class="shape8"><img src="assets/img/shape7.png" alt="image" /></div>
</div> -->

    <div class="partner-area ptb-70">
        <div class="container">
            <app-partner></app-partner>
        </div>
    </div>