import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BehaviorSubject } from 'rxjs';
import { TrainersService } from 'src/app/services/trainers.service';

@Component({
    selector: 'app-instructor',
    templateUrl: './instructor.component.html',
    styleUrls: ['./instructor.component.scss'],
})
export class InstructorComponent implements OnInit {
    courseAdvisor: any;

    advisorData = new BehaviorSubject(null);
    course: any;
    randomColor: any;
    colors: any[] = ['#0f8cff', '#ea576f', '#5cdb57', '#a768d7', '#e9e209', '#fca733'];

    constructor(
        private router: Router,
        private trainerService: TrainersService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.courseAdvisor = this.trainerService.courseAdvisor;
        let colorIndex = Math.floor(Math.random() * this.colors.length);
        this.randomColor = this.colors[colorIndex];
    }

    advisorSlides: OwlOptions = {
        loop: true,
        nav: false,
        dots: true,
        margin: 30,
        autoplay: true,
        autoplayHoverPause: true,
        navText: [
            "<i class='bx bx-chevron-left'></i>",
            "<i class='bx bx-chevron-right'></i>",
        ],
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 1,
            },
            768: {
                items: 2,
            },
            992: {
                items: 2,
            },
        },
    };

    toProfile(adv: any) {
        this.router.navigateByUrl(`profile/${adv?.id}`);
    }
}
