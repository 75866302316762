import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Meta, Title } from '@angular/platform-browser';
import { LinkService } from 'src/app/services/link.service';
import { BookDemoComponent } from '../../common/book-demo/book-demo.component';

@Component({
    selector: 'app-elearning-school',
    templateUrl: './elearning-school.component.html',
    styleUrls: ['./elearning-school.component.scss']
})
export class ElearningSchoolComponent implements OnInit {

    private dialogRef: any;
    stopAnimation = false;
    isPopupOpen = false;
    constructor(
        private title: Title,
        private meta: Meta,
        private linkService: LinkService,
        public dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.title.setTitle('CODEMIND Technology | Your Success Partner');
        this.meta.updateTag({ name: 'description', content: 'CodeMind Technology Pune: Custom Software, Web, and Mobile App Development with Skilled Team for Cost-Effective Solutions and IT Consulting' });
        this.meta.updateTag({ name: 'keywords', content: 'CodeMind Technology, Pune, Custom Software Development, Web Development, Mobile Application Development, IT Consulting, Skilled Developers, Project Managers, Cost-Effective Solutions, High-Quality Services' });
        this.linkService.addTag({ rel: 'canonical', href: 'http://codemindtechnology.com/' })
    }

    openDialog(): void {

        this.isPopupOpen = true;
        // this.stopAnimation = true;
        if (!this.dialogRef || !this.dialogRef.componentInstance) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.width = '500px';
            dialogConfig.data = {};
            dialogConfig.backdropClass = 'custom-dialog-backdrop';
            dialogConfig.hasBackdrop = false;
            dialogConfig.closeOnNavigation = false;
            dialogConfig.panelClass = "myCustomClass"
            this.dialogRef = this.dialog.open(BookDemoComponent, dialogConfig);
            this.dialogRef.componentInstance.closeDialog.subscribe(() => {
                this.dialogRef = null;
                this.isPopupOpen = false;
                this.linkService.updatePopupStatus(false); 
            });
        }
        this.linkService.updatePopupStatus(true);
    }

}