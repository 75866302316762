<!--<div class="why-choose-us-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-image">
                    <img src="assets/img/why-choose-us.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-content">
                    <span class="sub-title">WHY CHOOSE US</span>
                    <h2>BUILD GYM SKILLS WITH EXPERTS ANY TIME, ANYWHERE</h2>
                    <p>We understand better that online-based learning can make a significant change to reach students from all over the world! Giving options to learn better always can offer the best outcomes!</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-experience"></i> Skilled Trainers</span></li>
                        <li><span><i class="flaticon-time-left"></i> Afordable Courses</span></li>
                        <li><span><i class="flaticon-tutorials"></i> Efficient & Flexible</span></li>
                        <li><span><i class="flaticon-self-growth"></i> Lifetime Access</span></li>
                    </ul>
                    <a routerLink="/courses-1" class="default-btn"><i class="flaticon-user"></i>Join For Free<span></span></a>
                </div>
            </div>
        </div>
    </div>
</div>-->




<!--<div class="why-choose-us-area ptb-100">
    <div class="container-fluid">
       
        
     <div class="row align-items-center">
        <div class="col-md-3 mx-auto">
          
          <div class="section">
            <div class="row">
              <div class="col-lg-4">
             <img  class="colored-image" src="assets/img/whychoose/location.png">
              </div>
              <div class="col-lg-4">
                100% PLACEMENT ASSISTANT
              </div>
            </div>
          </div>
          <div class="section">
            <div class="row">
              <div class="col-lg-4">
                <img  class="colored-image" src="	https://rewards.aon.com/aon.rewards/media/images/icons/outline/icon-outline-achievement.png" >   
            </div>
              <div class="col-lg-4">
              100% SUCEESS RATE
              </div>
            </div>
          </div>
          <div class="section">
            <div class="row">
              <div class="col-lg-4">
               <img   class="colored-image" src="assets/img/whychoose/sucess.png">
              </div>
              <div class="col-lg-4">
                200+ SUCCESSFUL ALUMNI

              </div>
            </div>
          </div>
          <div class="section">
            <div class="row">
              <div class="col-lg-4">
             <img src="assets/img/whychoose/book.png" alt="">
              </div>
              <div class="col-lg-4">
                LEARN FROM SCRATCH
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 mx-auto">
           Second column 
          <div class="section">
            <div class="row">
              <div class="col-lg-4">
                <img src="assets/img/whychoose/mentor.png" alt="">
              </div>
              <div class="col-lg-4">
                1:1 PERSONAL MENTORSHIP FROM INDUSTRY EXPERTS
              </div>
            </div>
          </div>
          <div class="section">
            <div class="row">
              <div class="col-lg-6">
              <img src="assets/img/whychoose/package.png" alt="">
              </div>
              <div class="col-lg-6">
                Text Column
              </div>
            </div>
          </div>
          <div class="section">
            <div class="row">
              <div class="col-lg-6">
                Image Column
              </div>
              <div class="col-lg-6">
                Text Column
              </div>
            </div>
          </div>
          <div class="section">
            <div class="row">
              <div class="col-lg-6">
                Image Column
              </div>
              <div class="col-lg-6">
                Text Column
              </div>
            </div>
          </div>
        </div>
    </div>
    </div>
  </div>-->
<div class="container">
  <div class="why-choose-us-area ptb-100">
    <div class="container-fluid">
        <h2>Why Choose <span style="color:#0ce04f;">CODE</span>MIND?</h2>
     <div class="row align-items-center remove-gutters">
        <div class="col-md-4 mx-auto p-0">
          
                 <div class="container cart">
                <img src="assets/img/whychoose/location.png" alt="Your Image" class="image">
                <div class="text">
                  <p>100 % PLACEMENT
                    ASSISTANCE</p>
                </div>
             </div>
             <div class="container cart">
                <img src="assets/img/whychoose/salary.png" alt="Your Image" class="image">
                <div class="text">
                  <p>93% SUCCESS RATE</p>
                </div>
             </div>
             <div class="container cart">
                <img src="assets/img/whychoose/sucess.png" alt="Your Image" class="image">
                <div class="text">
                  <p>2000+ SUCCESSFUL
                    ALUMNI</p>
                </div>
             </div>
             <div class="container cart">
                <img src="assets/img/whychoose/book.png" alt="Your Image" class="image">
                <div class="text">
                  <p>LEARN FROM
                    SCRATCH
                    </p>
                </div>
             </div>
        </div>
        <div class="col-md-4 mx-auto p-0">
          
           <div class="container cart">
            <img src="assets/img/whychoose/mentor.png" alt="Your Image" class="image">
            <div class="text">
              <p>1:1 PERSONAL
                MENTORSHIP FROM
                INDUSTRY EXPERTS</p>
            </div>
         </div>
         <div class="container cart">
            <img src="assets/img/whychoose/package.png" alt="Your Image" class="image">
            <div class="text">
              <p>28 LPA(AVG.)CTC</p>
            </div>
         </div>
         <div class="container cart">
            <img src="assets/img/whychoose/lpa.png" alt="Your Image" class="image">
            <div class="text">
              <p>147% (AVG.) SALARY HIKE</p>
            </div>
         </div>
         <div class="container cart">
            <img src="assets/img/whychoose/sevice.png" alt="Your Image" class="image">
            <div class="text">
              <p>CAREER SERVICES</p>
            </div>
         </div>
        </div>
      </div>
    </div>
  </div>
  </div>

