import { Component, OnInit } from '@angular/core';
import { CoursesService } from '../services/courses.service';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-prompt-box',
    templateUrl: './prompt-box.component.html',
    styleUrls: ['./prompt-box.component.scss'],
})
export class PromptBoxComponent implements OnInit {
    selectedCourse: string;
    admissionForm!: FormGroup;
    getImgCion: any[];
    courses: string[] = [
        'Angular',
        'React',
        'Java',
        'DotNet',
        'Python',
        'Data Science',
        'Testing',
        'Devops',
    ];

    constructor(
        private coursesService: CoursesService,
        private formBuilder: FormBuilder,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.getImgCion = this.coursesService.allCourses;
        this.admissionForm = this.formBuilder.group({
            fullName: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required]),
            contactNumber: new FormControl('', [Validators.required]),
            course: new FormControl('', [Validators.required]),
        });
    }

    onSubmit(admissionForm: any) {
        let candidateData = admissionForm?.value;
        this.admissionForm.reset();
        this.coursesService.promptShow.next(false);
        this.toastr.success(
            'Thank you for the insterest, Our management team reach out to you soon!!',
            'Success'
        );
    }
}
