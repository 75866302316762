import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FeedbackService {
    feedback = [
        {
            image: 'assets/img/placed-candidate/swati.png',
            name: 'swati P',
            company: 'City Bank',
            quote: "CodeMind Technology is an excellent institute the way of teaching, explaining each concepts with real time examples in an easy manner helped me to learn and gain confidence in Java and the support they provide is really appreciated. I would thank my colleague who recommended me and thank you Umesh sir for all your support A #codemindTechnology",
            package: '28 LPA',
            designation: 'Java Developer',
        },
        {
            image: 'assets/img/placed-candidate/ganesh.png',
            name: 'Ganesh K',
            company: 'Excellarate Softech India PVT LTD',
            quote: "माझ्या पहिल्या जॉब चे कॉन्ट्रॅक्ट संपल्याचा Email आल्यानंतर मी खूप विचारत होतो. त्यानंतर काही दिवसांनी मित्रांकडून क्लास संबंधी माहिती मिळाली व मी क्लास जॉईन केला. गजानन सर, धनाजी सर आणि शिल्पा मॅम यांनी आम्हाला जॉब मिळावा यासाठी १००% प्रयत्न केले. तसेच गावाकडच्या मुलांना आयटीमध्ये कशाप्रकारे आणता येईल त्या दृष्टीने खूप प्रयत्न आणि ओढ पाहायला मिळाली. मला आपले प्रेरणादायी मार्गदर्शन लाभल्याबद्दल मी आपल्या पूर्ण Codemind Team ऋणी आहे",
            package: '3.12 LPA',
            designation: 'React Developer',
        },
        {
            image: 'assets/img/placed-candidate/rahul.png',
            name: 'Rahul I',
            company: 'Master Card',
            quote: "Codemind Technology played a pivotal role in my journey, as the constant guidance I received and the encouragement that was showered on me helped me learn better. I was lacking in communication and confidence. but this training helped me to get the good technical knowledge and also gave me confidence. The mentorship arrangement makes it all a fantastic experience.Thank you so much Codemind Team.",
            package: '2.4 LPA',
            designation: 'Angular Developer',
        },
        {
            image: 'assets/img/placed-candidate/prashant.png',
            name: 'Prashant P',
            company: 'Excellarate Softech India PVT LTD',
            quote: "I don't know about what is coding, what is angular but after 4 month i got the knowledge about front end technology and this happened because of the Codemind family, they teach us like a friend like a family, it was my turning point when i joined the Codemind and finally i have achieved what i want. Thanks to all Codemind family for your help. Thank you again.",
            package: '4.5 LPA',
            designation: 'Angular Developer',
        },
        {
            image: 'assets/img/placed-candidate/pankaj.png',
            name: 'pankaj P',
            company: 'Capita India PVT LTD',
            quote: "My dream to join product based company that dream comes true at the starting of my journey because of #CodeMind team so thank you a lots team you gave shape to my life. Specially thanks to Gajanan sir for your teaching and moral support is beyond the world. Thank you CodeMind for such great journey which I never end",
            package: '3.4 LPA',
            designation: 'Angular Developer',
        },
        // {
        //     image: 'assets/img/placed-candidate/sonali_k.png',
        //     name: 'Sonali K',
        //     company: 'Capgemini',
        //     quote: "Any Institute Success is determined by the right connectivity between the faculties and students and CodeMind Technology is the best example of that.It is very true that codemind is my success partner. After having a long gap of 10 years i completely lost the hope of getting good job in IT. I would specially thank to Dhanaji sir for their kind and moral support. Last but not least I am thanking code mind for giving",
        //     package: '6.8 LPA',
        //     designation: 'Angular Developer',
        // },
        {
            image: 'assets/img/placed-candidate/ritesh.png',
            name: 'Ritesh Z',
            company: 'Suma Soft',
            quote: "Finally my struggle is over, since from last 2 years I was searching for jobs but each type I failed and failed again. was completely hopeless, wasn't having confidence that wther, I am calibre enough to do the job or not but this training changed everything in my life. thank you so much for my team.",
            package: '2.8 LPA',
            designation: 'UI Developer',
        },
        {
            image: 'assets/img/placed-candidate/harshvardhan.png',
            name: 'Harshvardhan J',
            company: 'Discus Business Solutions',
            quote: "No words to express my feeling. Graduated in 2017 after that i have search the lot of technical and non technical job but nothing will be happen only contract based job. then i decided need to stable in any one field and then i can go with the Codemind Family and see the result i have started my carrior more than I expected. Codemind is the another family who will be take care of child as a mother.",
            package: '4.6 LPA',
            designation: 'Software Developer',
        },
        {
            image: 'assets/img/placed-candidate/ajay.png',
            name: 'Ajay V',
            company: 'bynaric System',
            quote: "Learning to write programs stretches your mind, and helps you think better, creates a way of thinking about things that I think is helpful in all domains and CodeMind is the place where you will learn how to write program and think about logic.Thank you so much CodeMind Team.",
            package: '3.0 LPA',
            designation: 'Java Developer',
        },
        {
            image: 'assets/img/placed-candidate/snehal.png',
            name: 'Snehal B',
            company: 'QualEx Systems',
            quote: "I never imagined that I would be able to start my career. Was competically demotivated and frustrated as well because I always used to see my friends there working on good positions and I was the only lacking behind. Thanks God I got the perfect guidance and mentorship along with notes. Due to which only I am able to start my career. Thank you so much Codemind team",
            package: '5.3 LPA',
            designation: 'Software Test Engineer',
        },
    ];
    constructor() { }
}
