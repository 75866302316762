<div class="coming-soon-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="coming-soon-content" >
                <div class="main-banner-content">
                    <div [align]="'center'">
                        <h1>
                            <span *ngFor="let letter of animText" [innerHTML]="letter"></span>
                        </h1>
                    </div>
                    <div [align]="'center'">
                        <strong>Your Success Partner</strong>
                    </div>
                </div>
                <h2>New batches will Start on <br> <b>15 April 2024</b> </h2>
                <div id="timer" class="flex-wrap d-flex justify-content-center">
                    <div id="days" class="align-items-center flex-column d-flex justify-content-center">{{days}}
                        <span>Days</span>
                    </div>
                    <div id="hours" class="align-items-center flex-column d-flex justify-content-center">{{hours}}
                        <span>Hours</span>
                    </div>
                    <div id="minutes" class="align-items-center flex-column d-flex justify-content-center">{{minutes}}
                        <span>Minutes</span>
                    </div>
                    <div id="seconds" class="align-items-center flex-column d-flex justify-content-center">{{seconds}}
                        <span>Seconds</span>
                    </div>
                </div>
                <div class="form-group my-3">
                    <h3 class="text-dark">Contact</h3>
                    <p class="text-dark">
                        Mobile:
                        <a href="tel:+44457895789" class="text-dark">(+91) - 9665044698</a>
                    </p>
                    <p class="text-dark">
                        Mobile:
                        <a href="tel:+44457895789" class="text-dark">(+91) - 9665044598</a>
                    </p>
                    <p class="text-dark">
                        Mail:
                        <a href="mailto:hello@odemy.com" class="text-dark">info@codemindtechnology.com</a>
                    </p>
                </div>
                <form class="newsletter-form" [formGroup]="newsLetterForm">
                    <div class="form-group">
                        <input type="email" class="input-newsletter" placeholder="Enter your email"
                            formControlName="email" required>
                        <span class="text-danger"
                            *ngIf="newsLetterForm.controls.email.invalid && newsLetterForm.controls.email.touched">Email
                            is required</span>
                        <span class="label-title ms-2"><i class='bx bx-envelope'></i></span>
                    </div>
                    <button type="submit" class="default-btn bg-dark mb-5 rounded" [disabled]="!newsLetterForm.valid"
                        (click)="onSubscribe(newsLetterForm.value)"><i
                            class="flaticon-user"></i>Subscribe<span></span></button>
                    <!-- <p>If you would like to be notified when your app is live, Please subscribe to our mailing list.</p> -->
                </form>
            </div>
        </div>
    </div>
</div>
