export const environment = {
  production: true,
  firebase: {
    production: true,
    apiKey: "AIzaSyCefiISOgENAEUBHbBdGPWm-LCzt7jZBcA",
    authDomain: "cm-tech.firebaseapp.com",
    projectId: "cm-tech",
    storageBucket: "cm-tech.appspot.com",
    messagingSenderId: "747200032108",
    appId: "1:747200032108:web:44f375f1cfd77f8e141749"
  }
};
