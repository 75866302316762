<div class="container">
    <div class=" row section-title oa-title-color">
        <span class="sub-title">Skilling talent that delivers</span>
        <h2>Meet Our Hiring Partners</h2>
    </div>

    <div class="row">
        <div class="scrolling-wrapper">
            <div class="card col-md-2 align-items-center justify-content-center" *ngFor="let logo of firstArray">
                <img [src]="logo?.image" [alt]="logo?.name" />
            </div>
        </div>
    </div>

    <div class="row rtl-row">
        <div class="scrolling-wrapper scrolling-wrapper rtl-scrolling">
            <div class="card col-md-2 align-items-center justify-content-center" *ngFor="let logo of secondArray">
                <img [src]="logo?.image" [alt]="logo?.name" />
            </div>
        </div>
    </div>
</div>