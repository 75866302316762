import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TrainersService } from 'src/app/services/trainers.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
    partialData: any;
    courseAdvisor: any;
    constructor(
        private route: ActivatedRoute,
        private trainerService: TrainersService
    ) { }

    ngOnInit(): void {
        this.getTrainerDetails();
    }

    getTrainerDetails() {
        const cardId = this.route.snapshot.paramMap.get('id');
        this.partialData = this.trainerService.searhedTrainer(cardId);
        this.courseAdvisor = {
            ...this.partialData,
            phone_number: 9665044698,
            email: 'info@codemindtechnology.com',
        };
    }
}
