import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CoursesService } from 'src/app/services/courses.service';

@Component({
    selector: 'app-course-details',
    templateUrl: './course-details.component.html',
    styleUrls: ['./course-details.component.scss'],
})
export class CourseDetailsComponent implements OnInit {
    filterdCoursId: any[] = [];

    session: any;
    course: {
        id: string;
        image: string;
        title: string;
        desc: string;
        view: string;
        syllabus: string;
    };

    constructor(
        private courseService: CoursesService,
        private route: ActivatedRoute,
        private auth: AuthService,
        private toastr: ToastrService,
        private router: Router
    ) { }

    ngOnInit(): void {
        window.location.reload()
        this.getCourseDetails();

    }
    getCourseDetails() {
        const cardId = this.route.snapshot.paramMap.get('id');
        this.course = this.courseService.searhedCourse(cardId);
    }
    downloadPDF(url: string) {
        // if (this.auth.isLoggedIn()) {
            window.open(url, '_blank');
        // } else {
        //     this.toastr.warning('You have to logged in to download the syllabus', 'WARNING');
        //     this.router.navigateByUrl('profile-authentication/login')
        // }
    }
}
