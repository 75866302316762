<div class="container">
    <div class="section-title">
        <span class="sub-title">placement</span>
        <h2>Our Recent Placements​</h2>
    </div>
    <div class="feedback-slides-two">
        <owl-carousel-o carousel [options]="candidateSlides">
            <ng-template carouselSlide *ngFor="let candidate of placedCandidates; let i = index">
                <div class="single-features-box " [class.blur-background]="isDialogOpen">
                    <b class="text-dark" [innerHTML]="candidate?.designation"></b>
                    <div class="icon">
                        <img class="candidate-img" [src]="candidate?.image" [alt]="candidate?.name" />
                    </div>
                    <b><small [innerHTML]="candidate?.name"></small></b><br />
                    <div class="PlacementCard_line"></div>
                    <div class="company">
                        <strong class="company-profile mt-4 text-dark" [innerHTML]="
                            candidate?.company + ' : ' + candidate?.package">
                        </strong>
                    </div>
                    <div>
                        <img class="placed-company-img" [src]="candidate?.image2" />
                    </div>
                    <button type="button" class="default-btn" (click)="openModal(candidate, i)">Read More</button>
                </div>
            </ng-template>
        </owl-carousel-o>
        <div class="modal-backdrop" [ngStyle]="{'display': displayBackdrop}" (click)="onCloseHandled()">
            <div class="modal popup shadow" tabindex="-1" role="dialog" [ngStyle]="{'display': display}">
                <div class="modal-dialog-center" role="document">
                    <div class="modal-content-center " style="height: 34vh;">
                        <div class="modal-header-center">
                            <h4 class="modal-title"> {{ placedCandidates[selectedCandidateIndex]?.name }}</h4>
                        </div>
                        <div class="modal-body row align-items-center">
                            <div class="iconimg col-md-3">
                                <img [src]="placedCandidates[selectedCandidateIndex]?.image" alt="">
                            </div>
                            <p class="col-md-9 align-items-center">
                                {{ placedCandidates[selectedCandidateIndex]?.quote }}
                            </p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger text-light"
                                (click)="onCloseHandled()">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>