<section>
    <div class="row">
        <div class="col-md-7">
            <div class="batches__enquiry">
                <div class="admission__enquiry">
                    <h2 class="enquiry__heading">
                        Training Starting From 15th Aug
                    </h2>
                    <div class="admission__courses">
                        <div class="row justify-content-center">
                            <div
                                class="col-lg-4 col-md-6 col-sm-12"
                                *ngFor="let icon of getImgCion"
                            >
                                <div class="course">
                                    <img [src]="icon?.image" alt="Angular" />
                                    <p class="text-overflow">
                                        {{ icon?.title | slice : 0 : 25 }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div>
                <div class="admission__enquiry">
                    <h2 class="enquiry__heading">Admission Enquiry</h2>
                    <hr />
                    <div class="admission-form">
                        <form [formGroup]="admissionForm">
                            <div class="form-group">
                                <input
                                    type="text"
                                    class="form-control"
                                    formControlName="fullName"
                                    placeholder="Full Name"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        admissionForm.controls['fullName']
                                            .touched &&
                                        admissionForm.controls['fullName']
                                            .invalid
                                    "
                                    >This is required field*</span
                                >
                            </div>
                            <div class="form-group">
                                <input
                                    type="email"
                                    class="form-control"
                                    formControlName="email"
                                    placeholder="Email"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        admissionForm.controls['email']
                                            .touched &&
                                        admissionForm.controls['email'].invalid
                                    "
                                    >This is required field*</span
                                >
                            </div>
                            <div class="form-group">
                                <input
                                    type="number"
                                    class="form-control"
                                    formControlName="contactNumber"
                                    placeholder="Contact Number"
                                />
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        admissionForm.controls['contactNumber']
                                            .touched &&
                                        admissionForm.controls['contactNumber']
                                            .invalid
                                    "
                                    >This is required field*</span
                                >
                            </div>
                            <div class="form-group">
                                <select
                                    class="form-control"
                                    placeholder="Select Course"
                                    formControlName="course"
                                    [(ngModel)]="selectedCourse"
                                >
                                    <option value="">Select Course</option>
                                    <option
                                        *ngFor="let course of courses"
                                        [value]="course"
                                    >
                                        {{ course }}
                                    </option>
                                </select>
                                <span
                                    class="text-danger"
                                    *ngIf="
                                        admissionForm.controls['course']
                                            .touched &&
                                        admissionForm.controls['course'].invalid
                                    "
                                    >This is required field*</span
                                >
                            </div>
                            <div class="form-group">
                                <button
                                    [disabled]="!admissionForm.valid"
                                    class="btn btn-outline-success form-control"
                                    (click)="onSubmit(admissionForm)"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
