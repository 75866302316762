<div class="header">
    <div class="row">
        <div class="col-md-2 email">
            <p class="title">Email Us:</p>
            <a href="mailto:hello@odemy.com">info@codemindtechnology.com</a>
        </div>
        <div class="col-md-3">
            <div class="review-info">
                <div class="right-item">
                    <p class="title">Google Rating:</p>
                    <div class="review-rating">
                        <div class="review-stars">
                            <i class="bx bxs-star checked"></i>
                            <i class="bx bxs-star checked"></i>
                            <i class="bx bxs-star checked"></i>
                            <i class="bx bxs-star checked"></i>
                            <i class="bx bxs-star checked"></i>
                            <!-- <i class="fa fa-star-half-o" style="color:#f2b827"></i> -->
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 call">
            <p class="title">Call Us:</p>
            <small><a href="tel:+44457895789">(+91) - 9665 0446 98</a></small>&nbsp;&nbsp;<small><a
                    href="tel:+44457895789">(+91) - 9665 0445 98</a></small>
        </div>
       
        <div class="col-md-3 social-link desktop">
            <li style="border:2px solid #d6249f; border-radius: 50%;" class="insta">
                <a href="https://www.instagram.com/codemind_technology_official/"class="d-block" target="_blank">
                    <i style="color:#d6249f" class="bx bxl-instagram"></i>
                </a>
            </li>
            <li style="border:2px solid #007bb6; border-radius: 50%;">
                <a href="https://in.linkedin.com/company/codemind-technology" class="d-block" target="_blank">
                    <i style='color: #007bb6' class="bx bxl-linkedin"></i></a>
            </li>
            <li style="border:2px solid #4265b1; border-radius: 50%;">
                <a href="https://www.facebook.com/CodemindTechnologyLLP/" class="d-block" target="_blank">
                    <i style='color: #4265b1' class=' bx bxl-facebook'></i></a>
            </li>
           <!-- <li style="border:2px solid #bb0000; border-radius: 50%;">
                <a href="" class="d-block" target="_blank">
                    <i style='color: #bb0000' class='bx bxl-youtube'></i></a>
            </li>-->
        </div> 
    </div>
</div>



<div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied "
            [class.active]="isNavbarOpen" (click)="toggleNavbar()">
            <a class="navbar-brand" routerLink="/"><img class="logoImg" src="assets/img/logo-shape.png"
                    alt="logo" /></a>
                   
            <!-- <h3 class="navbar-header"><span class="green-text">CODE</span>MIND TECHNOLOGY</h3> -->
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a routerLink="/" class="nav-link">HOME </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="featured-courses" class="nav-link">COURSES</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="our-recent-placements" class="nav-link">PLACEMENTS</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="events" class="nav-link">EVENTS</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="about-us" class="nav-link">ABOUT US</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="contact-us" class="nav-link">CONTACT US</a>
                    </li>
                    <!-- <li class="nav-item" >
                        <a routerLink="events/campus-drive-records" class="nav-link">TPO SECTION</a>
                    </li> -->
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item">
                        <a routerLink="/" class="nav-link"><i class="fas fa-home"></i>HOME</a>
                    </li>
                    <li class="nav-item megamenu">
                        <a routerLink="featured-courses" class="nav-link">COURSES</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="our-recent-placements" class="nav-link">PLACEMENTS</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="events" class="nav-link">EVENTS</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="about-us" class="nav-link">ABOUT US</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="contact-us" class="nav-link">CONTACT US</a>
                    </li>
                </ul>
            </div>
        </nav>
        <!-- <div class="pendulum">
            <img (click)="goToHire()" src="../../../../assets/img/enrollNow (2).png" alt="Pendulum Image">
        </div> -->
    </div>
</div>
<div class="overlay" *ngIf="isNavbarOpen" (click)="toggleNavbar()"></div>