<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-3">
                <div class="single-footer-widget text-center">
                    <a routerLink="/" class="logo"><img class="footer-logo" src="assets/img/logo-shape.png"
                            alt="logo" /></a>
                    <p>
                        Unlock your potential with <b>CODEMIND Technology</b>'s transformative courses in software
                        development,
                        web design, and data science. Join us and embark on a journey of knowledge and growth.
                    </p>

                </div>
                <div class="col-3 images">
                    <img src="assets/img/header/iso4.png">
                    <img src="assets/img/header/mca.png">
                    <img src="assets/img/header/msme.jpg">

                </div>
            </div>
            <div class="col-3">
                <div class="single-footer-widget pl-5">
                    <h3>NAVIGATIONS</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about-us">About</a></li>
                        <li><a routerLink="/featured-courses">Courses</a></li>
                        <li><a routerLink="/events">Events</a></li>
                        <li><a routerLink="/contact-us">Contact</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-3">
                <div class="single-footer-widget">
                    <h3>RESOURCES</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/our-recent-placements">Student Success</a></li>
                        <!-- <li><a routerLink="#">For Business</a></li>-->
                        <!--  <li><a routerLink="events">Go Premium</a></li>-->
                        <!--<li><a routerLink="#">Team Plans</a></li>-->
                    </ul>
                </div>
            </div>
            <div class="col-3">
                <div class="single-footer-widget">
                    <h3>ADDREESS</h3>
                    <ul class="footer-contact-info">
                        <li>
                            <i class="bx bx-map"></i>Ashtvinayak Building,
                            <br />4th-Floor, Vinayak Colony, Kirti Classic, Near
                            Luxmi Chowk, Hinjewadi, Pune-411058.
                        </li>
                        <li>
                            <i class="bx bx-phone-call"></i><a href="tel:+44587154756">(+91) - 9665044698</a>
                        </li>
                        <li>
                            <i class="bx bx-phone-call"></i><a href="tel:+44587154756">(+91) - 9665044598</a>
                        </li>
                        <li>
                            <i class="bx bx-envelope"></i><a
                                href="mailto:info@codemindtechnology.com">info@codemindtechnology.com</a>
                        </li>

                        <!-- <li>
                                <a href="#" class="d-block" target="_blank"
                                    ><i class="bx bxl-facebook"></i
                                ></a>
                            </li> -->
                        <!-- <li>
                                <a href="#" class="d-block" target="_blank"
                                    ><i class="bx bxl-twitter"></i
                                ></a>
                            </li> -->
                        <div class="social-link">
                            <li >
                                <a style="border:2px solid#d6249f;" href="https://www.instagram.com/codemind_technology_official/" class="d-block" target="_blank">
                                    <i style="color:#d6249f" class="bx bxl-instagram"></i></a>
                            </li>
                            <li>
                                <a style="border:2px solid #007bb6;" href="https://in.linkedin.com/company/codemind-technology" class="d-block" target="_blank">
                                    <i style="color: #007bb6"  class="bx bxl-linkedin"></i></a>
                            </li>
                            <li>
                                <a style="border:2px solid#4265b1;" href="https://www.facebook.com/CodemindTechnologyLLP/" class="d-block"
                                    target="_blank">
                                    <i style="color: #4265b1"  class='bx bxl-facebook'></i></a>
                            </li>
                            <!--<li>
                                <a style="border:2px solid #bb0000;" href="" class="d-block" target="_blank">
                                    <i style="color: #bb0000"  class='bx bxl-youtube'></i></a>
                            </li>-->
                        </div>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>© 2024 | Alrights reserved by <b>CODEMIND Technology</b></p>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li>
                            <a routerLink="/privacy-policy">Privacy Policy</a>
                        </li>
                        <li>
                            <a routerLink="/terms-of-service">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>