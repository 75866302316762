import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LightgalleryModule } from 'lightgallery/angular';
import { StickyNavModule } from 'ng2-sticky-nav';
import { AccordionModule } from 'ngx-accordion';
import { CountUpModule } from 'ngx-countup';
import { LightboxModule } from 'ngx-lightbox';
import { CarouselModule } from 'ngx-owl-carousel-o';
// import { OwlCarousel } from 'ngx-owl-carousel-o';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { TabsModule } from 'ngx-tabset';

import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment.prod';
import { AboutStyleOneComponent } from './about/about-style-one/about-style-one.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplyInstructorComponent } from './components/common/apply-instructor/apply-instructor.component';
import { CoursesTypesComponent } from './components/common/courses-types/courses-types.component';
import { CoursesComponent } from './components/common/courses/courses.component';
import { FeaturedComponent } from './components/common/featured/featured.component';
import { FeaturesFunfactsComponent } from './components/common/features-funfacts/features-funfacts.component';
import { FeaturesStyleOneComponent } from './components/common/features-style-one/features-style-one.component';
import { FeaturesComponent } from './components/common/features/features.component';
import { FeedbackWithBgComponent } from './components/common/feedback-with-bg/feedback-with-bg.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { FunfactsFeedbackComponent } from './components/common/funfacts-feedback/funfacts-feedback.component';
import { FunfactsStyleOneComponent } from './components/common/funfacts-style-one/funfacts-style-one.component';
import { GetInstantCoursesTwoComponent } from './components/common/get-instant-courses-two/get-instant-courses-two.component';
import { GetInstantCoursesComponent } from './components/common/get-instant-courses/get-instant-courses.component';
import { InstructorComponent } from './components/common/instructor/instructor.component';
import { LifestyleComponent } from './components/common/lifestyle/lifestyle.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { OverviewComponent } from './components/common/overview/overview.component';
import { PartnerSliderComponent } from './components/common/partner-slider/partner-slider.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { PremiumAccessTwoComponent } from './components/common/premium-access-two/premium-access-two.component';
import { PremiumAccessComponent } from './components/common/premium-access/premium-access.component';
import { SelectedAgesComponent } from './components/common/selected-ages/selected-ages.component';
import { ServicesComponent } from './components/common/services/services.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { TestimonialsStyleTwoComponent } from './components/common/testimonials-style-two/testimonials-style-two.component';
import { TestimonialsComponent } from './components/common/testimonials/testimonials.component';
import { TopCategoriesComponent } from './components/common/top-categories/top-categories.component';
import { TrainingComponent } from './components/common/training/training.component';
import { UpcomingEventsComponent } from './components/common/upcoming-events/upcoming-events.component';
import { VideoStyleTwoComponent } from './components/common/video-style-two/video-style-two.component';
import { VideoComponent } from './components/common/video/video.component';
import { ViewAllCoursesTwoComponent } from './components/common/view-all-courses-two/view-all-courses-two.component';
import { ViewAllCoursesComponent } from './components/common/view-all-courses/view-all-courses.component';
import { WhyChooseUsComponent } from './components/common/why-choose-us/why-choose-us.component';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { CourseDetailsComponent } from './components/pages/elearning-school/course-details/course-details.component';
import { ElearningAboutComponent } from './components/pages/elearning-school/elearning-about/elearning-about.component';
import { ElearningBannerComponent } from './components/pages/elearning-school/elearning-banner/elearning-banner.component';
import { ElearningSchoolComponent } from './components/pages/elearning-school/elearning-school.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ProfileQuizComponent } from './components/pages/profile-quiz/profile-quiz.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { TermsOfServiceComponent } from './components/pages/terms-of-service/terms-of-service.component';
import { PlacementsComponent } from './components/placements/placements.component';
import { ContactUsComponent } from './contact/contact-us/contact-us.component';
import { CoursesDetailsStyleOneComponent } from './courses/courses-details-style-one/courses-details-style-one.component';
import { CoursesStyleOneComponent } from './courses/courses-style-one/courses-style-one.component';
import { EventsDetailsComponent } from './events/events-details/events-details.component';
import { EventsComponent } from './events/events/events.component';
import { PromptBoxComponent } from './prompt-box/prompt-box.component';
import { ScannerComponent } from './scanner/scanner.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { BookDemoComponent } from './components/common/book-demo/book-demo.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { VideoDetailsComponent } from './components/common/video-details/video-details.component';

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        FooterComponent,
        ElearningSchoolComponent,
        PartnerComponent,
        PremiumAccessComponent,
        SubscribeComponent,
        ContactUsComponent,
        AboutStyleOneComponent,
        ErrorComponent,
        ComingSoonComponent,
        PrivacyPolicyComponent,
        TermsOfServiceComponent,
        CoursesStyleOneComponent,
        CoursesDetailsStyleOneComponent,
        ProfileComponent,
        ProfileQuizComponent,
        CategoriesComponent,
        EventsComponent,
        EventsDetailsComponent,
        ElearningBannerComponent,
        FeaturesStyleOneComponent,
        ElearningAboutComponent,
        ViewAllCoursesComponent,
        GetInstantCoursesComponent,
        FunfactsFeedbackComponent,
        VideoComponent,
        CoursesComponent,
        ViewAllCoursesTwoComponent,
        InstructorComponent,
        TestimonialsComponent,
        PremiumAccessTwoComponent,
        FunfactsStyleOneComponent,
        TopCategoriesComponent,
        FeedbackComponent,
        FeedbackWithBgComponent,
        UpcomingEventsComponent,
        OverviewComponent,
        TrainingComponent,
        LifestyleComponent,
        ServicesComponent,
        SelectedAgesComponent,
        FeaturedComponent,
        WhyChooseUsComponent,
        ApplyInstructorComponent,
        TestimonialsStyleTwoComponent,
        GetInstantCoursesTwoComponent,
        PartnerSliderComponent,
        VideoStyleTwoComponent,
        CoursesTypesComponent,
        FeaturesFunfactsComponent,
        FeaturesComponent,
        CourseDetailsComponent,
        PlacementsComponent,
        PromptBoxComponent,
        ScannerComponent,
        BookDemoComponent,
        VideoDetailsComponent,
    ],
    imports: [
        BrowserModule,
        CarouselModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CountUpModule,
        StickyNavModule,
        TabsModule,
        NgxScrollTopModule,
        LightboxModule,
        AccordionModule,
        FormsModule,
        LightgalleryModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        ReactiveFormsModule,
        ToastrModule.forRoot(),
        AngularFirestoreModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatExpansionModule
        // OwlCarousel
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule { }
