import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  constructor() { }
  getAllreviews(){
    return[
      {
        image:   'assets/img/placed-candidate/sushant.png',
        name: 'Sushant J',
        review:"Codemind technology is the best institute that help you in your career .the training centre had very friendly and the staff is always there to help us i learn lot of things here"
      },
      {
        image:'assets/img/placed-candidate/dnyaneshwar.png',
        name: 'Dnyaneshwar R',
        review:"An academy, where your dream IT career dream will fulfill I joined here in last month and enjoying a lot as teaching staff is extra ordinary and takes care of queries of every last student."
      },
      {
        image:'assets/img/placed-candidate/abhijeet.png',
        name: 'Abhijeet K',
        review:"I had a very  nice experience in codemind technology .It is  one of the best training center.They also  take weekly interviews for students to improve their communication. "
      },
      {
        image:'assets/img/placed-candidate/abdul.png',
        name: 'Abdul N',
        review:"Best place to learn with a best quality teaching. Affordable fees with good infrastructure. Good faculty and teaching staff is very friendly, they teach in depth 100% practical based. "
      },
      {
        image: 'assets/img/placed-candidate/dada.png',
        name: 'Dada G',
        review:"Those who don't know about technology Codemind is best place to start study. They will know each of you what you suits better.They more focus on clear concept. Also take mock interviews."
      },
      {
        image: 'assets/img/placed-candidate/sanket.png',
                name: 'Sanket M',
        review:"Codemind Technology is the best training institute, it is the best platform for students where they can build their career in IT field. I would like to thank you for the helpful guidance provided."
      },
      {
        image: 'assets/img/placed-candidate/pooja.png',
        name: 'Pooja J',
      review:"One of  The Best Training institute In Pune. They Will  Teach you From Scratch to become perfect. Staff Is Very friendly and help you in every step. Everyday Assignment and Mock Interview. "
      },
      {
        image: 'assets/img/placed-candidate/vaibhav.png',
        name: 'Vaibhav G',
        review:"Best place to learn with a best quality teaching. Affordable fees with good infrastructure. Good faculty and teaching staff is very friendly, they teach in depth 100% practical based. Thanks Codemind Technology."
      },
      {
        image: 'assets/img/placed-candidate/dipali.png',
        name: 'Dipali K',
        review:"I recently joined for an online Angular training batch with the Code Mind Technology .This is the best training center ever seen in pune. Trainer's have the best knowledge and they have much guidance."
      },
      {
        image: 'assets/img/placed-candidate/megha.png',
                name: 'Megha K',
        review:"Codemind technology is the best Institute to start carrier in IT industry.The training is excellent & also provide recording facility for revising the session. The knowledge given by the sir is very helpful to me ."
      },
     {
      image: 'assets/img/placed-candidate/ruturaj.png',
      name: 'Ruturaj K',
      review:"Codemind technology is the best Institute for non IT student. Because in the Institute they teach all subjects in depth and also they provide live interaction class. Teachers are very good and supportive."
     },
     {
      image: 'assets/img/placed-candidate/rahul.png',
      name: 'Rahul I',
      review:"Codemind Technology is the best training institute, it is the best platform for students where they can build their career in IT field. I would like to thank you for the helpful guidance provided by teachers. "
     },
     {
      image: 'assets/img/placed-candidate/prashant.png',
      name: 'Prashant P',
      review:"In March I was selected in Codemind Technology. The overall selection process was arranged in very well manner.I am glad to have a training institute like this.I liked that everything is straight forward. "
      
     },
     {
      image: 'assets/img/placed-candidate/pankaj.png',
      name: 'pankaj P',
      review:"An academy, where your dream IT career dream will fulfill I joined here in last month and enjoying a lot as teaching staff is extra ordinary and takes care of queries of every last student."
     },
     {
      image: 'assets/img/placed-candidate/harshvardhan.png',
                name: 'Harshvardhan J',
      review:"Codemind technology is best training institute to start your career in IT for those who belongs to non IT background. All concept are teached in their best way . If you are looking for job in IT sector."
     }
    ]
  }
}
