<!--<div class="about-area bg-fef8ef ptb-70">
    <div class="container">
         <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image">
                                <img src="assets/img/courses/learn-something-new.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image">
                                <img src="assets/img/courses/grow-yourself.png" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image">
                                <img src="assets/img/courses/skill.jpg" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image">
                                <img src="assets/img/courses/team.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Online learning</span>
                    <h2>Develop Your Skills, Learn Something New, and Grow Your Skills From Anywhere in the World!</h2>
                    <p>
                        Unlock your potential, wherever you are. <strong>CODEMIND Technology</strong> brings the
                        world of
                        learning to your fingertips. Expand your skills, gain new knowledge, and grow professionally
                        from anywhere in the world. Join their dynamic programs and embark on a journey of limitless
                        possibilities.</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-experience"></i> Expert Trainers</span></li>
                        <li><span><i class="flaticon-time-left"></i> Lifetime Acces</span></li>
                        <li><span><i class="flaticon-tutorials"></i> Remote Learning</span></li>
                        <li><span><i class="flaticon-self-growth"></i> Self Development</span></li>
                    </ul>
                    <a routerLink="featured-courses" class="default-btn">View All Courses<span></span></a>
                </div>
            </div>
        </div> 
    </div>
     <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div> 
</div>-->
<!--<div class="eligible">
    <div class="main">
        <h3 class="heading">WHO CAN DO THIS COURSE ?</h3>
    </div>
    
    <div class="row">
        <div class="col-md-3">
            <ul>
                <li>Freshers</li>
                <li>BE/Bsc Candidate</li>
                <li>Any Engineers</li>
            </ul>
        </div>
        <div class="col-md-3">
            <ul>
                <li>Any Graduate</li>
                <li>Any Post Graduate</li>
                <li>Working Professionals</li>
            </ul>
        </div>
    </div>
</div>-->


<!--<owl-carousel-o [options]="courseSlides">
    <ng-template carouselSlide>
        <div class="col-8">
            <img class="drive-img" src="assets/img/campus-drive/sveri/sveri-2.jpg"
                alt="campus drive at SVERI college Pandharpur">
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="col-8">
            <img class="drive-img" src="assets/img/campus-drive/sveri/sveri-3.jpg"
                alt="campus drive at SVERI college Pandharpur">
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="col-8">
            <img class="drive-img" src="assets/img/campus-drive/karmyogi/karmayogi-1.jpg"
                alt="campus drive at Karmyogi college Pandharpur">
        </div>
    </ng-template>
</owl-carousel-o>-->

<!--<div class="container">
    <h1 class="text-center ">Here are some of students got placed after pursing our course</h1>
    <div class="feedback-slides-two">
<owl-carousel-o [options]="courseSlides">
    <ng-template carouselSlide>
        <div class="single-features-box">
        <div class="col-6">
           <video width="380" height="330" controls>
                <source src="assets/img/candidateVideo/five.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            
             
        </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
       <div class="single-features-box">

        <div class="col-6">
            <video width="380" height="330" controls>
                <source src="assets/img/candidateVideo/third.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>
        </div>
        </div>
    </ng-template>
     <ng-template carouselSlide>
        <div class="single-features-box">
        <div class="col-6">
            <video width="380" height="330" controls>
                <source src="assets/img/candidateVideo/first.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>
        </div>
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="single-features-box">
        <div class="col-6">
            <video width="380" height="330" controls>
                <source src="assets/img/candidateVideo/fourth.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>
        </div>
        </div>
    </ng-template>

</owl-carousel-o>
</div>
</div>-->



<!--<div class="container">
    <h1 class="text-center">Here are some of the students who got placed after pursuing our course</h1>
    <div class="feedback-slides-two">
        <owl-carousel-o [options]="courseSlides">
            <ng-template carouselSlide *ngFor="let video of videos; let i = index">
                <div class="single-features-box" (click)="openVideoDetails(i)">
                    <div class="col-6">
                        <video width="380" height="330" controls>
                            <source [src]="video.src" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>-->
<div class="container">
    <h1 class="text-center">Testimonials By Codemind Students</h1>
    <!-- <div class="feedback-slides-two">
      <owl-carousel-o [options]="courseSlides">
        <ng-template carouselSlide *ngFor="let video of videos; let i = index">
          <div class="single-features-box" (click)="openVideoModal(video.src)">
            <div class="col-6">
              <video width="380" height="330" controls>
                <source [src]="video.src" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </ng-template>
       
      </owl-carousel-o>-->
     <div class="container">
    <div class="feedback-slides-two">
        <owl-carousel-o [options]="courseSlides2">
            <ng-template carouselSlide *ngFor="let image of images; let i = index">
                <div class="single-features-box">
                    <div class="col-6">
                        <img [src]="image.src" width="420" >
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
   
</div>

</div>