import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

import { PlacementsService } from 'src/app/services/placements.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class PartnerComponent implements OnInit {
  @ViewChild('carousel') carousel: any | undefined;

  placedCandidates: any[] = [];
  colors: any[] = ['#80ed99', '#80ed99', '#80ed99', '#80ed99', '#80ed99', '#80ed99'];
  randomColor: any;
  displayStyle = 'none';
  display = 'none';
  selectedCandidateIndex: number | null = null;
  isDialogOpen = false;
  displayBackdrop: string = 'none';

  constructor(private placementService: PlacementsService) { }

  ngOnInit(): void {
    this.placedCandidates = this.placementService.getAllPlacedCandidates();
    let colorIndex = Math.floor(Math.random() * this.colors.length);
    this.randomColor = this.colors[colorIndex];
  }

  candidateSlides: OwlOptions = {
    nav: true,
    dots: false,
    autoplayHoverPause: false,
    margin: 30,
    navText: ['<', '>'],
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 4,
      },
      1200: {
        items: 4,
      },
    },
  };

  openModal(candidate: any, index: number) {
    this.selectedCandidateIndex = index;
    this.display = 'block';
    this.displayBackdrop = 'block';
    this.isDialogOpen = true;
  }

  onCloseHandled() {
    this.selectedCandidateIndex = null;
    this.display = 'none';
    this.displayBackdrop = 'none';
    this.isDialogOpen = false;
  }

}
