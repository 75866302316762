<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <img src="assets/img/codemind-banner.png" alt="codemind-banner" />
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Our Featured Courses</li>
            </ul>
            <h2>Our Featured Courses</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>


<div class="container">
    <div class="feedback-slides-two">
        <div class="row">
            <div class="col-md-6" carouselSlide *ngFor="let course of featuredCourses">

                <div class="single-features-box">
                    <div class="icon">
                        <h3 [innerHTML]="course?.title"></h3>
                        <!--<img class="course-img" [src]="course?.image" />-->

                        <div class="list">
                            <ol>
                                <li>Duration 5 Months</li>
                                <li>Technologies you will learn</li>
                            </ol>
                        </div>
                    </div>
                    <div>
                    </div>
                    <div class="planet">
                        <div class="row first">
                            <div class="col-3">
                                <div class="item">
                                    <img class="logo" [src]="course?.logo1" />
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="item">
                                    <img class="logo" [src]="course?.logo2" />
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="item">
                                    <img class="logo" [src]="course?.logo3" />
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="item">
                                    <img class="logo" [src]="course?.image" />
                                </div>

                            </div>
                        </div>
                        <div class="row second">
                            <div class="col-4">
                                <div class="item">
                                    <img class="logo" [src]="course?.logo4" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="item">
                                    <img class="logo" [src]="course?.logo5" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="item">
                                    <img class="logo" [src]="course?.logo6" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="courseCard">
                        <button type="button" (click)="getCourseDetails(course)" [innerHTML]="course?.view">Learn
                            More</button>
                        <button type="button" (click)="downloadPDF(course?.syllabus)">Curriculam</button>
                    </div>

                    <!-- <p class="duration" [innerHTML]="course?.duration"></p> -->


                    <!-- <div class="link">
                    <a (click)="getCourseDetails(course)" class="link-btn" [innerHTML]="course?.view"></a>
                </div> -->
                </div>

            </div>
        </div>
    </div>
</div>