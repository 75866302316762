<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <img src="assets/img/codemind-banner.png" alt="codemind-banner" />
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Terms of Service</li>
            </ul>
            <h2>Terms of Service</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>

<div class="terms-of-service-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="terms-of-service-content">
                    <img
                        src="assets/img/codemind-banner.png"
                        alt="codemind-banner"
                    />
                    <p>
                        <b
                            >CODEMIND is owned, maintained and operated by
                            CODEMIND Technology. This Agreement is a legally
                            binding contract between “You” Or “Customer” and
                            CODEMIND PRIVATE LIMITED (which will be referred as
                            “ZENOXIT” for the rest of agreement). CODEMIND
                            provides the Service(s) (as defined below) to you
                            subject to and conditioned upon your acceptance of
                            this Agreement. BY ACCESSING AND USING THE SERVICE
                            IN ANY WAY, YOU AGREE TO BE BOUND BY ALL THE TERMS
                            OF THIS AGREEMENT. YOU CAN ACCEPT THE TERMS BY
                            CHECKING A CHECKBOX OR CLICKING ON A BUTTON
                            INDICATING YOUR ACCEPTANCE OF THE TERMS OR BY
                            ACTUALLY USING THE SERVICES. CODEMIND may modify
                            this Agreement from time to time and such
                            modification shall be effective upon posting by on
                            the Website at
                            https://codemind.com/TermsOfService.aspx You agree
                            to be bound to any changes to this Agreement when
                            you use the Services after any such modification is
                            posted. It is therefore important that you review
                            this Agreement regularly upon each use of the
                            Services to ensure you are updated as to any
                            changes.</b
                        >
                    </p>
                    <h2>Definition</h2>
                    <h3>Agreement</h3>
                    <p>This contract between CODEMIND and its Customers.</p>

                    <h3>Customer</h3>

                    <p>
                        An individual or legal entity that pays to use the
                        CODEMIND Application.
                    </p>
                    <h3>Customer Data</h3>
                    <p>
                        All Contact Information and other permitted information
                        submitted by Customer to, or collected by Customer via,
                        the Service. Customer Data does not include Sensitive
                        Information.
                    </p>
                    <h3>CODEMIND Application</h3>
                    <p>
                        The site of applications available at
                        https://codemindtechnology.com or any of its sub-domain.
                    </p>
                    <h3>CODEMIND Website</h3>
                    <a href="https://codemindtechnology.com"
                        >https://codemindtechnology.com</a
                    >

                    <h3>Invoice</h3>
                    <p>
                        An order confirmation form that captures key terms &
                        conditions of the Services that Customer purchases from
                        CODEMIND.
                    </p>

                    <h3>Service(s)</h3>
                    <p>
                        Means the products, modules, tools and services, whether
                        chargeable or not, that are ordered by You and/or made
                        available, from time to time, by CODEMIND online via the
                        Website and/or other web pages designated by CODEMIND,
                        including, without limitation, Third Party Services.
                    </p>

                    <h3>Term or Contract Term</h3>
                    <p>
                        Total duration of contract for which CODEMIND needs to
                        provide Services to the Customer as per Invoice.
                    </p>

                    <h3>Users</h3>
                    <p>
                        Individual people or accounts that are designated and
                        Authorized by Customer to access CODEMIND Application.
                    </p>
                    <h3>You or Your</h3>
                    <p>
                        Means the company or other legal entity for which you
                        are accepting this Agreement and Affiliates of that
                        company or entity.
                    </p>
                    <h2>Eligibility</h2>
                    <p>
                        Use of the Services is void where prohibited. By using
                        the Services, You represent and warrant that all
                        registration information You submit is truthful and
                        accurate; You will maintain the accuracy of such
                        information; You are 18 years of age or older; and Your
                        use of the Services does not violate any applicable law
                        or regulation. Your profile may be deleted and your
                        registration as a member with the Website may be
                        terminated without warning, if CODEMIND believes that
                        you are less than 18 years of age. By using the Premium
                        Services, You declare and represent that You are more
                        than 18 years of age and that You have full legal
                        capacity to complete such contractual action, including
                        the use of a credit card or use of any other method of
                        payment, without need in any additional approvals or
                        consents.
                    </p>
                    <h2>CODEMIND Services</h2>
                    <p>
                        CODEMIND provide institute management application. You
                        may use the Services for your personal and business use
                        or for internal business purpose in the organization
                        that you represent. You may connect to the Services
                        using any Internet browser supported by the Services.
                        You are responsible for obtaining access to the Internet
                        and the equipment necessary to use the Services. This
                        Agreement grants you the possibility of using of the
                        software of CODEMIND via Internet. CODEMIND is
                        constantly innovating in order to provide the best
                        possible experience for its users. You acknowledge and
                        agree that the form and nature of the Services, which
                        CODEMIND provides, may change from time to time without
                        prior notice to you. This Agreement grants you the
                        possibility of using of the software of CODEMIND via
                        Internet.CODEMIND is constantly innovating in order to
                        provide the best possible experience for its users. You
                        acknowledge and agree that the form and nature of the
                        Services, which CODEMIND provides, may change from time
                        to time without prior notice to you. As part of this
                        continuing innovation, you acknowledge and agree that if
                        CODEMIND disables access to Your account, You may be
                        prevented from accessing the Services, Your account
                        details or any files or other content which is contained
                        in Your account, hence, CODEMIND strongly recommends
                        that You store any content You insert into the Services
                        as any time You access to the Services. You acknowledge
                        and agree that while CODEMIND may not currently have set
                        a fixed upper limit on the amount of storage space used
                        for the provision of any Service, such fixed upper
                        limits may be set by CODEMIND at any time, at ZENOXIT’s
                        discretion. By entering into this Agreement you confirm
                        that your purchase decision is not dependent on any oral
                        or written public comments made by CODEMIND regarding
                        future functionality or features.
                    </p>
                    <h2>Services Availability and Usage</h2>
                    <p>
                        CODEMIND will use commercially reasonable effort to make
                        Services available 24×7 except in case of planned
                        Downtime (defined below) and Force Majeure (defined
                        later in the agreement). CODEMIND may shut down the
                        Services to perform planned maintenance of the platform
                        or to upgrade the platform. To the extent possible and
                        reasonable, we will schedule such downtime in
                        non-business hours for majority of our Customers over
                        weekend. We will provide at least 8 hours of notice for
                        such downtime.
                    </p>
                    <h2>Customer Rights & Responsibilities</h2>
                    <p>
                        CODEMIND grants a non-transferable, non-exclusive,
                        worldwide right to permit Users authorized by Customer
                        to access and use the Services subject to the terms of
                        the Agreement.
                    </p>
                    <h2>Customer agrees that</h2>
                    <p>
                        Customer will comply with this agreement in Service
                        usage. Customer will prevent unauthorized access to the
                        Service and Customer Data. Customer is responsible to
                        provide computers, computer operating system and web
                        browser to access Services. Customer shall consume the
                        Services only for its own internal business operations,
                        and not for the operation of a service bureau or
                        time-sharing service, or otherwise for the benefit of a
                        third party. Customer will follow applicable laws of its
                        own Country governing usage of the Services.
                    </p>
                    <h2>Service Usage Limitations</h2>
                    <p>
                        The use of Services may be limited by criteria specified
                        at www.codemind.com and may be more accurately described
                        in Invoice. Some examples of limitation are: number of
                        emails that can be sent by Customer in a month, number
                        of Contacts that can be managed. Any overuse of
                        Services, if technically permitted, will be reported and
                        may lead to additional billing to or suspension of
                        Customer Account or both after due intimation to
                        Customer.
                    </p>
                    <h2>Restrictions on Use</h2>
                    <p>
                        In addition to all other terms and conditions of this
                        Agreement, you shall not: transfer the Services or
                        otherwise make it available to any third party; provide
                        any service based on the Services without prior written
                        permission; use the third party links to sites without
                        agreeing to their website terms & conditions; post links
                        to third party sites or use their logo, company name,
                        etc. without their prior written permission; publish any
                        personal or confidential information belonging to any
                        person or entity without obtaining consent from such
                        person or entity; use the Services in any manner that
                        could damage, disable, overburden, impair or harm any
                        server, network, computer system, resource of CODEMIND;
                        violate any applicable local, state, national or
                        international law; and create a false identity to
                        mislead any person as to the identity or origin of any
                        communication.
                    </p>
                    <h2>Anti Spam Policy</h2>
                    <h3>What is SPAM?</h3>
                    <p>
                        Spam is any email or SMS you send to someone who hasn’t
                        given you their direct permission to contact them on the
                        topic of the email. When you send an email or SMS to
                        someone you don't know, that's an "unsolicited" email or
                        SMS. Sending one unsolicited message to someone is
                        obviously not spam. But when you send an unsolicited
                        email or SMS to an entire list of people you don't know,
                        that's spam.
                    </p>
                    <h3>Anti Spam Policy for Bulk SMS</h3>
                    <p>
                        You agree to abide by all applicable local, national and
                        international laws and regulations. You are solely
                        responsible for all acts or omissions that occur under
                        your account, including the content of the messages
                        transmitted through the service. The SMS service that
                        sends/receives messages to/from mobile phones is
                        maintained by CODEMIND. The utilization of the SMS
                        service is subject to the following Terms of Service.
                        Any Spam or illegal use of the SMS service is strictly
                        prohibited. Messages containing sexual, racist or
                        discriminatory content or any such usage of them may be
                        considered as harassment and you are to be held
                        responsible for this. CODEMIND does not assume any
                        liability for the content of messages sent. CODEMIND
                        will be exempt from any claim that may arise from third
                        parties as a result of message content. You guarantee
                        that the content of any SMS always respects and does not
                        in any way conflict with fundamental human rights or
                        will follow the norms of Intellectual Property Right
                        laws. (E.g. right to privacy, prohibition of
                        discrimination on any ground such as sex, race, color,
                        language, religion, political or other opinion, national
                        or social origin). You accept that the service is
                        provided for professional use only and you agree not to
                        use it to: Promote alcohol, music, dancing, dating,
                        gambling or deception. Send unsolicited messages (i.e.
                        mobile spam) and to therefore ensure that your messages
                        are only sent to individuals that have given you their
                        permission. Harvest, or otherwise collect information
                        about others, without their consent. Mislead others as
                        to the identity of the sender of your messages, by
                        creating a false identity, impersonating the identity of
                        someone/something else or by providing contact details
                        that do not belong to you. Transmit, associate or
                        publish any unlawful, racist, harassing, libelous,
                        abusive, threatening, demeaning, lewd, immoral, harmful,
                        vulgar, obscene or otherwise objectionable material of
                        any kind. As a general guideline, if your content is not
                        suitable for ages 13+, it most likely goes against our
                        Usage Policy. Transmit any material that may infringe
                        upon the intellectual property rights of third parties
                        including trademarks, copyrights or other rights of
                        publicity. Transmit any material that contains viruses,
                        Trojan horses, worm, time bombs, cancel-bots or any
                        other harmful/deleterious programs. Interfere with, or
                        disrupt, networks connected to the service or violate
                        the regulations, policies or procedures of such
                        networks. Attempt to gain unauthorized access to the
                        service, other accounts, computer systems or networks
                        connected to the service, through password mining or any
                        other means. Interfere with others use and enjoyment of
                        the service. Engage in any other activity that CODEMIND
                        believes could subject it to criminal liability or civil
                        penalty/judgment. You acknowledge that CODEMIND delivers
                        SMS messages via major telecom operators and can
                        therefore only influence the delivery transmission of
                        SMS messages within the technical constraints imposed.
                        SMS messages submitted via Internet will be transferred
                        to mobile network providers, provided that the
                        recipient's phone is switched on and doesn't have a full
                        memory and is located in an area covered by its
                        subscribed mobile network provider. You acknowledge
                        that, depending on the recipient's mobile provider
                        service, it may not be possible to transmit the SMS
                        message to the recipient successfully. You are
                        responsible for the privacy and storage of the user-name
                        and password. You agree that all activities made through
                        your account are legally bonded to you. CODEMIND neither
                        claims nor guarantees the availability or performance of
                        this service and accepts no liability for transmission
                        delays or message failures. While CODEMIND makes every
                        effort to deliver messages promptly. CODEMIND doesn't
                        refund the credits for undeliverable SMS messages to you
                        because we cannot guarantee delivery of the SMS messages
                        to recipients due to possible errors. CODEMIND debits
                        transmitted SMS messages according to its transmission
                        logs. These logs are deemed correct and valid even if
                        the customer has objected to the correctness of the
                        accounting records, except if investigation by CODEMIND
                        has produced evidence of a technical problem or error.
                        CODEMIND reserves the right to exclude you from using
                        this service, refunding you any remaining amount in your
                        account. All purchases must be considered as final, in
                        compliance with our no refund policy. The SMS account
                        balance is non-refundable and does not bear interest.
                        All the SMS must be used in the validity period from the
                        date of the purchase and no carry forward of the credits
                        will be made unless otherwise defined in a separate
                        contract. You under take the whole responsibilities
                        regarding the messages sending through ZENOXITservices
                        from your account, which are transmitted as per your
                        request. You shall indemnify and reimburse CODEMIND all
                        liabilities, costs, losses and damages, in case of any
                        claim brought against CODEMIND from any third party due
                        to breach of contract. By registering to CODEMIND I
                        agree to receive promotional SMS, email, voicecalls send
                        from CODEMIND.
                    </p>
                    <h2>Anti Spam Policy for Emails</h2>
                    <h3>
                        What kind of email addresses are OK to send to with
                        CODEMIND?
                    </h3>
                    <p>
                        To send email to anyone, you need to have their
                        permission. This could be done through: An email
                        newsletter subscribe form on your web site. An opt-in
                        checkbox on a form. This checkbox must not be checked by
                        default, the person completing the form must willingly
                        select the checkbox to indicate they want to hear from
                        you. If someone completes an offline form like a survey
                        or enters a competition, you can only contact them if it
                        was explained to them that they would be contacted by
                        email AND they ticked a box indicating they would like
                        to be contacted. Customers who have purchased from you
                        within the last 2 years.
                    </p>
                    <h3>What content MUST include in your email?</h3>
                    <p>
                        Every email you send using CODEMIND must include a
                        single-click “unsubscribe” link that instantly removes
                        the subscriber from your list. Once they unsubscribe,
                        you can never email them again.
                    </p>
                    <h3>Account Suspension</h3>
                    <p>
                        We reserve the right to suspend your account immediately
                        and start investigating your activity if your campaigns
                        have high percentage of spam complaints, bounces,
                        unsubscribes or very small open rate. If you breach this
                        anti-spam policy (or attempts to do so) will immediately
                        be suspended without notice. If it turns out that you
                        were sending emails without permission - we will
                        terminate your account. We can ask you to prove that you
                        have permission from your recipients and we can close
                        your account if you do not have such proof. Otherwise,
                        we will activate your account and you will be able to
                        use the service again.
                    </p>
                    <h2>Proprietary Rights</h2>
                    <p>
                        You acknowledge and agree that CODEMIND own all legal
                        right, title and interest in and to the Services,
                        including any intellectual property rights which subsist
                        in the Services (whether those rights happen to be
                        registered or not, and wherever in the world those
                        rights may exist). You further acknowledge that the
                        Services may contain information which is designated
                        confidential by CODEMIND and that You shall not disclose
                        such information without ZENOXIT’s prior written
                        consent. Unless You have agreed otherwise in writing
                        with CODEMIND, nothing in this Agreement gives You a
                        right to use any of ZENOXIT’s trade names, trademarks,
                        service marks, logos, domain names, and other
                        distinctive brand features. You agree that You shall not
                        remove, obscure, or alter any proprietary rights notices
                        (including copyright and trade mark notices) which may
                        be affixed to or contained within the Services.Unless
                        You have been expressly authorized to do so in writing
                        by CODEMIND, you agree that in using the Services, You
                        will not use any trade mark, service mark, trade name,
                        logo of any company or organization in a way that is
                        likely or intended to cause confusion about the owner or
                        authorized user of such marks, names or logos.
                    </p>
                    <h2>Suspension and Termination</h2>
                    <p>
                        We may suspend your user account or temporarily disable
                        access to whole or part of any Service in the event of
                        any suspected illegal activity, extended periods of
                        inactivity or requests by law enforcement or other
                        government agencies. Objections to suspension or
                        disabling of user accounts should be made to
                        support@codemind.com within thirty days of being
                        notified about the suspension. We may terminate a
                        suspended or disabled user account after thirty days. We
                        will also terminate your user account on your request.
                        In addition, we reserve the right to terminate your user
                        account and deny the Services upon reasonable belief
                        that you have violated the Terms and to terminate your
                        access to any Beta Service in case of unexpected
                        technical issues or discontinuation of the Beta Service.
                        You have the right to terminate your user account if
                        CODEMIND breaches its obligations under these Terms and
                        in such event. Termination of user account will include
                        denial of access to all Services, deletion of
                        information in your user account such as your email
                        address and password and deletion of all data in your
                        user account.
                    </p>
                    <h2>Indemnification</h2>
                    <p>
                        You agree to defend, indemnify and hold harmless
                        CODEMIND, its parent corporation, officers, directors,
                        employees and agents, from and against any and all
                        claims, damages, obligations, losses, liabilities, costs
                        or debt, and expenses (including but not limited to
                        attorney's fees) arising from: Your use of and access to
                        the Website and/or the Services; Your violation of any
                        term of this Agreement; Your violation of any third
                        party right, including without limitation any copyright,
                        property, or privacy right; or any claim that one of
                        Your content/data caused damage to a third party. This
                        defense and indemnification obligation will survive the
                        termination of the Services and of this Agreement.
                    </p>
                    <h2>Disclaimer of Warranties, Limitation of Liability</h2>
                    <p>
                        You expressly agree that your use of, or inability to
                        use, the services is at your sole risk. Services and any
                        products ordered, or used, by you through the services
                        are provided as is and as available for your use,
                        without warranties of any kind, either express or
                        implied, including all implied warranties of
                        merchantability, fitness for a particular purpose, title
                        and non-infringement. Because some jurisdictions do not
                        allow the exclusion of implied warranties, the above
                        exclusion of implied warranties may not apply to you.
                        Please keep in mind that some of the content that we
                        make available to you through the services comes from
                        partners or any other third party web services. All such
                        content is provided to you as is, as-available basis and
                        CODEMIND, the partners and other third party licensors,
                        do not make and hereby disclaims and warranty, express
                        or implied, with respect to the content, including, but
                        not limited to, the accuracy, completeness, timeliness,
                        merchantability or fitness for a particular purpose of
                        the content or of the media on which the content is
                        provided. The content and your use of it are subject to
                        change and/or removal at any time. In no case shall
                        CODEMIND, its affiliates, partners, and their respective
                        directors, officers, employees, agents or contractors be
                        liable for any direct, indirect, incidental, special or
                        consequential damages arising from your use of any of
                        the services or for any other claim related in any way
                        to your use of the services. Because some jurisdictions
                        do not allow the exclusion or the limitation of
                        liability for consequential or incidental damages, in
                        such jurisdictions, CODEMIND liability shall be limited
                        to the extent permitted by applicable local law. Without
                        limiting the foregoing, in particular, CODEMIND, its
                        affiliates, partners, and their respective directors,
                        officers, employees, agents or contractors, and its
                        licensors do not represent or warrant to you that: Your
                        use of the services will meet your requirements, Your
                        use of the services will be uninterrupted, timely,
                        secure or free from error, Any information obtained by
                        you as a result of your use of the services will be
                        accurate or reliable, and Defects in the operation or
                        functionality of any software provided to you as part of
                        the services will be corrected. Any material downloaded
                        or otherwise obtained through the use of the services is
                        done at your own discretion and risk and that you will
                        be solely responsible for any damage to your computer
                        system or other device or loss of data that results from
                        the download of any such material. No advice or
                        information, whether oral or written, obtained by you
                        from CODEMIND or through or from the services shall
                        create any warranty not expressly stated in the terms.
                        CODEMIND, its affiliates, partners, and their respective
                        directors, officers, employees, agents or contractors,
                        and its licensors shall not be liable to you for any
                        loss or damage which may be incurred by you, including
                        but not limited to loss or damage as a result of: The
                        deletion of, corruption of, or failure to store, any
                        content and other communications data maintained or
                        transmitted by or through your use of the services; Any
                        reliance placed by you on the completeness, accuracy or
                        existence of any content, or as a result of any
                        relationship or transaction between you and any third
                        party whose advertising, services or content appears on
                        the services; Any changes which CODEMIND may make to the
                        services, or for any permanent or temporary cessation in
                        the provision of the services (or any features within
                        the services); Your failure to provide CODEMIND with
                        accurate account information; Your failure to keep your
                        password or account details secure and confidential.
                    </p>
                    <h2>Beta Service Warning</h2>
                    <p>
                        Please note that the Website is currently in its BETA
                        version and undergoing BETA testing. You understand and
                        agree that the Services may still contain software bugs,
                        suffer disruptions and not operate as intended or
                        designated. Your use of the Services at this stage
                        signifies your understanding of and agreement to
                        participate in the Service BETA testing. CODEMIND
                        reserves the right to change the content, design and
                        business plan when sees needed without advance
                        notification.
                    </p>
                    <h2>Information or Complaints</h2>
                    <p>
                        If we receive a complaint from any person against you
                        with respect to your activities as part of use of the
                        Services, we will forward the complaint to the primary
                        email address of your user account. You must respond to
                        the complainant directly within 10 days of receiving the
                        complaint forwarded by us and copy CODEMIND in the
                        communication. If you do not respond to the complainant
                        within 10 days from the date of our email to you, we may
                        disclose your name and contact information to the
                        complainant for enabling the complainant to take legal
                        action against you. You understand that your failure to
                        respond to the forwarded complaint within the 10 days’
                        time limit will be construed as your consent to
                        disclosure of your name and contact information by
                        CODEMIND to the complainant. If you have a question or
                        complaint regarding CODEMIND Website, please feel free
                        to contact us via e-mail at info@codemindtechnology.com
                        You may also contact us by writing to us at: CODEMIND
                        PRIVATE LIMITED, 603-B1/Daisy, Mukta Residency,
                        Kalyan-Shil Road, Padle Gaon, Thane - 421204 Land Mark :
                        Near Honda Showroom
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
