<div
    class="feedback-with-bg-image ptb-100"
    style="background-image: url('assets/img/feeback-with-bg.png')"
>
    <div class="container">
        <div class="feedback-slides feedback-slides-style-two">
            <owl-carousel-o [options]="feedbackSlides">
                <ng-template
                    carouselSlide
                    *ngFor="let feedback of feedbackByDeveloper"
                >
                    <div class="single-feedback-item-box">
                        <p [innerHTML]="feedback?.quote"></p>
                        <div class="client-info d-flex align-items-center">
                            <img
                                [src]="feedback?.image"
                                [alt]="feedback?.name"
                            />
                            <div class="title">
                                <h3 [innerHTML]="feedback?.name"></h3>
                                <p>
                                    <span
                                        [innerHTML]="
                                            feedback?.company +
                                            ' : ' +
                                            feedback?.designation
                                        "
                                    ></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>
