import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private angularFireAuth: AngularFireAuth,
    private router: Router,
    private toastr: ToastrService) { }


  isLoggedIn() {
    if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
      return true
    }
    return false;
  }

  register(email: string, password: string) {
    this.angularFireAuth.createUserWithEmailAndPassword(email, password).then((res) => {
      this.router.navigateByUrl('profile-authentication/login')
      this.toastr.success('Registered Successfully', 'SUCCESS')
      this.sendEmailForVerification(res.user.email)
    }, error => {
      this.toastr.error(error.message, 'ERROR')
      throw error?.message
    })
  }

  login(email: string, password: string) {
    this.angularFireAuth.signInWithEmailAndPassword(email, password).then((res) => {
      localStorage.setItem('token', res.user.uid)
      this.toastr.success('Logged In Successfully', 'SUCCESS')

      if (res && res.user && res.user.emailVerified == true) {
        this.router.navigateByUrl('');
      } else {
        this.router.navigateByUrl('profile-authentication/verify-email');
      }
    }, error => {
      this.toastr.error(error.message, 'ERROR')
      throw error?.message
    })
  }

  logout() {
    this.angularFireAuth.signOut().then(() => {
      this.toastr.info('Logged Out', 'INFO')
      localStorage.clear();
      this.router.navigateByUrl('profile-authentication/login')
    })
  }

  forgorPassword(email: any) {
    this.angularFireAuth.sendPasswordResetEmail(email.email).then((res: any) => {
      this.router.navigateByUrl('profile-authentication/verify-email')
    }, error => {
      this.toastr.error(error.message, 'ERROR');
    })
  }

  sendEmailForVerification(user: any) {
    user.sendEmailForVerification().then((res) => {
      this.router.navigateByUrl('profile-authentication/verify-email')
    }, error => {
      this.toastr.error(error.message, 'ERROR')
    })
  }

}
