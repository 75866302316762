import { Component, OnInit } from '@angular/core';
import { HiringPartnerService } from 'src/app/services/hiring-partner.service';

@Component({
  selector: 'app-premium-access',
  templateUrl: './premium-access.component.html',
  styleUrls: ['./premium-access.component.scss'],
})
export class PremiumAccessComponent implements OnInit {
  hierdParnters: any;
  firstArray: any;
  secondArray: any;
  constructor(private hiring_partnerService: HiringPartnerService) { }

  ngOnInit(): void {
    this.getData();
    this.devideArray();
  }

  getData() {
    this.hierdParnters = this.hiring_partnerService.partners;
  }

  devideArray() {
    const halfwayIndex = Math.floor(this.hierdParnters.length / 2)
    this.firstArray = this.hierdParnters.slice(0, halfwayIndex);
    this.secondArray = this.hierdParnters.slice(halfwayIndex)


  }
}
