import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras,NavigationStart,NavigationEnd} from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CoursesService } from 'src/app/services/courses.service';

@Component({
    selector: 'app-features-style-one',
    templateUrl: './features-style-one.component.html',
    styleUrls: ['./features-style-one.component.scss'],
})
export class FeaturesStyleOneComponent implements OnInit {
    featuredCourses: any[] = [];
    colors: any[] = [
        '#80ed99',
        '#80ed99',
        '#80ed99',
        '#80ed99',
        '#80ed99',
        '#80ed99',
    ];
    randomColor: any;

    constructor(
        private courseService: CoursesService,
        private router: Router,
        private auth: AuthService,
        private toastr: ToastrService,
        
    ) {
        // this.router.events.subscribe((event) => {
        //     if (event instanceof NavigationStart) {
        //       window.scrollTo(0, 0);
        //     }
        //   });
        // this.router.events.subscribe((event) => {
        //     if (event instanceof NavigationEnd) {
        //       window.scrollTo(0, 0);
        //     }
        //   });
        // Subscribe to router events to detect navigation changes
    // this.router.events.subscribe((event) => {
    //     if (event instanceof NavigationStart) {
    //       // Scroll to the top of the page when navigation is complete
    //       window.scrollTo(0, 0);
    //     }
    //   });
    // this.router.events.subscribe((event) => {
    //     if (event instanceof NavigationEnd) {
    //       document.body.scrollTop = 0;
    //       document.documentElement.scrollTop = 0;
    //     }
    //   });
    
    }

    ngOnInit(): void {
   
        this.featuredCourses = this.courseService.allCourses;
       
        //for (const iterator of this.featuredCourses) {
          //  if (iterator.title === 'ANGULAR') {
            //    iterator.title = 'ANGUALR (MEAN STACK)'
            //}
            
      //  }
        // let colorIndex = Math.floor(Math.random() * this.colors.length);
        // this.randomColor = this.colors[colorIndex];
    }
    featuredCourseSlide: OwlOptions = {
        loop: true,
        nav: false,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        navText: [
            "<i class='bx bx-chevron-left'></i>",
            "<i class='bx bx-chevron-right'></i>",
        ],
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 1,
            },
            768: {
                items: 4,
            },
            1200: {
                items: 4,
            },
        },
    };
   
    getCourseDetails(course: any) {
        debugger;
        // const navigationExtras: NavigationExtras = {
        //     skipLocationChange: true,
        //   };
        this.router.navigateByUrl(`/featured-courses/course/${course?.url}`);
        // this.router.navigate([`/featured-courses/course/${course?.url}`]);
        // window.scrollTo(0, 0);
        // setTimeout(() => {
        //     window.scrollTo(0, 0);
        //   });
       
        
      
    }

    downloadPDF(url: string) {
        // if (this.auth.isLoggedIn()) {
            window.open(url, '_blank');
        // } else {
            // this.toastr.warning('You have to logged in to download the syllabus', 'WARNING');
            // this.router.navigateByUrl('profile-authentication/login')
        // }
    }

  

  
       
    
     
   
}
