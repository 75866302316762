import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CoursesService } from 'src/app/services/courses.service';

@Component({
    selector: 'app-elearning-banner',
    templateUrl: './elearning-banner.component.html',
    styleUrls: ['./elearning-banner.component.scss'],
})
export class ElearningBannerComponent implements OnInit {
    randomColor: any;
    constructor(private courseService: CoursesService) { }

    customCourses: any[] = [];
    colors: any[] = ['#80ed99', '#80ed99', '#80ed99', '#80ed99', '#80ed99', '#80ed99'];
    animText = [
        'C',
        'O',
        'D',
        'E',
        'M',
        'I',
        'N',
        'D',
        '&nbsp',
        'T',
        'E',
        'C',
        'H',
        'N',
        'O',
        'L',
        'O',
        'G',
        'Y',
    ];

    ngOnInit(): void {
        this.customCourses = this.courseService.allCourses;
        let colorIndex = Math.floor(Math.random() * this.colors.length);
        this.randomColor = this.colors[colorIndex];
    }

    
  courseSlides: OwlOptions = {
    items: 1,
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayHoverPause: true,
};
}
