import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private apiUrl='https://api.zenoxerp.com/api/Registration/Enquiry/InsertCaptureLead'
  constructor(private http: HttpClient) { }
  
  sendFormData(formData: any): Observable<any> {
    // debugger
    console.log("contact service form value :", formData);
    
  return this.http.post(this.apiUrl, formData);
   }
 
}

  


