import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { CourseDetailsComponent } from './components/pages/elearning-school/course-details/course-details.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ProfileQuizComponent } from './components/pages/profile-quiz/profile-quiz.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { TermsOfServiceComponent } from './components/pages/terms-of-service/terms-of-service.component';
import { PlacementsComponent } from './components/placements/placements.component';
import { ScannerComponent } from './scanner/scanner.component';
import { VideoDetailsComponent } from './components/common/video-details/video-details.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'featured-courses',
        loadChildren: () => import('./courses/courses.module').then(m => m.CoursesModule)
    },
    {
        path: 'events',
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
    },

    {
        path: 'about-us',
        loadChildren: () => import('./about/about.module').then(m => m.AboutModule)
    },
    {
        path: 'contact-us',
        loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
    },
    {
        path: 'profile-authentication',
        loadChildren: () => import('./profile-authentication/profile-authentication.module').then(m => m.ProfileAuthenticationModule)
    },
    {
        path: 'campus-connect',
        loadChildren: () => import('./campus-connect/campus-connect.module').then(m => m.CampusConnectModule)
    },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'terms-of-service', component: TermsOfServiceComponent },
    { path: 'profile/:id', component: ProfileComponent },
    { path: 'profile-quiz', component: ProfileQuizComponent },
    { path: 'categories', component: CategoriesComponent },
    // { path: 'course/:id', component: CourseDetailsComponent },
    { path: 'our-recent-placements', component: PlacementsComponent },
    { path: 'scanner', component: ScannerComponent },
    { path: 'video-details/:videoId', component: VideoDetailsComponent },

    { path: '**', component: ErrorComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy'}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
