import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { LinkService } from 'src/app/services/link.service';

@Component({
    selector: 'app-about-style-one',
    templateUrl: './about-style-one.component.html',
    styleUrls: ['./about-style-one.component.scss'],
})
export class AboutStyleOneComponent implements OnInit {
    descImages = [
        {
            image: 'assets/img/Redefining-Employee-Engagement-.png',
            name: 'Redefining-Employee-Engagement',
        },
        {
            image: 'assets/img/goal-achievement.png',
            name: 'goal-achievement',
        },
        {
            image: 'assets/img/promoted.png',
            name: 'promoted',
        },
        {
            image: 'assets/img/how-to-measure-employee-engagement.jpg',
            name: 'measure-employee-engagement',
        },
    ];
    constructor(
        private title: Title,
        private meta: Meta,
        private linkService: LinkService) { }

    ngOnInit(): void {
        this.title.setTitle('About Us | Your Success Partner');
        this.meta.updateTag({ name: 'description', content: "Looking for a trusted Success partner? Look no further than our about us page. Learn more about our company and how we can help you succeed with your career." })
        this.linkService.addTag({ rel: 'canonical', href: 'http://codemindtechnology.com/about-us' })

    }
}
