import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FeedbackService } from 'src/app/services/feedback.service';

@Component({
    selector: 'app-feedback-with-bg',
    templateUrl: './feedback-with-bg.component.html',
    styleUrls: ['./feedback-with-bg.component.scss'],
})
export class FeedbackWithBgComponent implements OnInit {
    feedbackByDeveloper: any;
    constructor(private feedbacSevice: FeedbackService) {}

    ngOnInit(): void {
        this.feedbackByDeveloper = this.feedbacSevice.feedback;
    }

    feedbackSlides: OwlOptions = {
        loop: true,
        nav: false,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        animateOut: 'fadeOut',
        autoHeight: true,
        items: 1,
        navText: [
            "<i class='bx bx-chevron-left'></i>",
            "<i class='bx bx-chevron-right'></i>",
        ],
    };
}
