import { Component, OnInit } from '@angular/core';
import { PlacementsService } from 'src/app/services/placements.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ReviewService } from 'src/app/services/review.service';
@Component({
  selector: 'app-view-all-courses',
  templateUrl: './view-all-courses.component.html',
  styleUrls: ['./view-all-courses.component.scss']
})
export class ViewAllCoursesComponent implements OnInit {

  CandidateReview: any[] = [];
  colors: any[] = ['#80ed99', '#80ed99', '#80ed99', '#80ed99', '#80ed99', '#80ed99'];
  randomColor: any;
  displayStyle = 'none';
  display = 'none';
  selectedCandidateIndex: number | null = null;
  carousel: any;
  constructor(
    private review: ReviewService
  ) { }

  ngOnInit(): void {
    this.CandidateReview = this.review.getAllreviews();
    let colorIndex = Math.floor(Math.random() * this.colors.length);
    this.randomColor = this.colors[colorIndex];
  }

  candidateSlides: OwlOptions = {
    nav: true,
    dots: false,
    autoplayHoverPause: false,
    margin: 10,
    navText: ["<", ">"],
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 4,
      },
      1200: {
        items: 4,
      },
    },
  };


}