import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { LinkService } from 'src/app/services/link.service';
import { PlacementsService } from 'src/app/services/placements.service';

@Component({
    selector: 'app-placements',
    templateUrl: './placements.component.html',
    styleUrls: ['./placements.component.scss']
})
export class PlacementsComponent implements OnInit {
    isDialogOpen = false;
    selectedCandidateIndex: number | null = null;
    display = 'none';
    placedCandidates: any[] = [];
    randomColor: any;
    displayBackdrop: string = 'none';
    colors: any[] = ['#80ed99', '#80ed99', '#80ed99', '#80ed99', '#80ed99', '#80ed99'];
    constructor(
        private placementService: PlacementsService,
        private title: Title,
        private meta: Meta,
        private linkServcie: LinkService
    ) { }
    allPlacedCandidate: any[];

    ngOnInit(): void {
        this.title.setTitle('All Placed Candidates Under The CODEMIND Technology | Your Success Partner');
        this.meta.updateTag({ name: 'description', content: 'Enjoy the top learning methods and achieve next level skills! You are the creator of your own career & we will guide you through that' });
        this.linkServcie.addTag({ rel: 'canonical', href: 'http://codemindtechnology.com/our-recent-placements' })


        this.allPlacedCandidate = this.placementService.getAllPlacedCandidates();
      console.log(this.allPlacedCandidate, "allPlacedCandidate");

        let colorIndex = Math.floor(Math.random() * this.colors.length);
        this.randomColor = this.colors[colorIndex];

    }
    openModal(candidate: any, index: number) {
        this.selectedCandidateIndex = index;
        this.display = 'block';
        this.displayBackdrop = 'block';
        this.isDialogOpen = true;
      }
    
      onCloseHandled() {
        this.selectedCandidateIndex = null;
        this.display = 'none';
        this.displayBackdrop = 'none'; 
        this.isDialogOpen = false;
      }
}
