import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent implements OnInit {
  
  goToPlacement() {
    this.router.navigateByUrl(`/our-recent-placements`);
  }

  goToTraining() {
    this.router.navigateByUrl(`/featured-courses`);
  }
  
  goToContact(){
    this.router.navigateByUrl('/contact-us');
  }
  
  constructor(private router: Router) { }

  ngOnInit(): void {}

}
