<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Global Conference on Business Management and Economics</li>
            </ul>
            <h2>Global Conference on Business Management and Economics</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="events-details-area pb-100">
    <div class="events-details-image">
        <img src="assets/img/events/events-details.jpg" alt="image">
        <div id="timer" class="flex-wrap d-flex justify-content-center">
            <div id="days" class="align-items-center flex-column d-flex justify-content-center">{{days}}
                <span>Days</span></div>
            <div id="hours" class="align-items-center flex-column d-flex justify-content-center">{{hours}}
                <span>Hours</span></div>
            <div id="minutes" class="align-items-center flex-column d-flex justify-content-center">{{minutes}}
                <span>Minutes</span></div>
            <div id="seconds" class="align-items-center flex-column d-flex justify-content-center">{{seconds}}
                <span>Seconds</span></div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="events-details-header">
                    <ul>
                        <li><i class="bx bx-calendar"></i>Jan 1, 2021 - Jan 31, 2021</li>
                        <li><i class="bx bx-map"></i>Rome, Italy</li>
                        <li><i class="bx bx-time"></i>12:00 AM - 12:00 PM</li>
                    </ul>
                </div>
                <div class="events-details-location">
                    <div id="map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d105913.76628212207!2d-117.46956773183814!3d33.946138126927174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dca6df7ff47dbb%3A0xf7a1d705135e0ae8!2sRiverside%2C%20CA%2C%20USA!5e0!3m2!1sen!2sbd!4v1592581354634!5m2!1sen!2sbd"></iframe>
                    </div>
                </div>
                <div class="events-details-desc">
                    <h3>About The Event</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                        maecenas accumsan lacus vel facilisis.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                        maecenas accumsan lacus vel facilisis.</p>
                    <h3>Where the event?</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                        maecenas accumsan lacus vel facilisis.</p>
                    <h3>Who this event is for?</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                        maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="events-details-info">
                    <ul class="info">
                        <li class="price">
                            <div class="d-flex justify-content-between align-items-center">
                                <span>Cost</span>
                                $149
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span>Total Slot</span>
                                1500
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span>Booked Slot</span>
                                350
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span>Pay With</span>
                                <div class="payment-method">
                                    <img src="assets/img/payment/img1.png" class="shadow" alt="image">
                                    <img src="assets/img/payment/img2.png" class="shadow" alt="image">
                                    <img src="assets/img/payment/img3.png" class="shadow" alt="image">
                                    <img src="assets/img/payment/img4.png" class="shadow" alt="image">
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/single-events" class="default-btn"><i class="flaticon-user"></i>Book
                            Now<span></span></a>
                        <p>You must <a routerLink="/profile-authentication">login</a> before register event.</p>
                    </div>
                    <div class="events-share">
                        <div class="share-info">
                            <span>Share This Course <i class="flaticon-share"></i></span>
                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a>
                                </li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="advisor-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Event Participants</h2>
        </div>
        <div class="advisor-slides-two owl-carousel owl-theme">
            <div class="single-advisor-item">
                <div class="advisor-image">
                    <img src="assets/img/advisor/img4.jpg" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
                <div class="advisor-content">
                    <h3><a routerLink="/profile">James Andy</a></h3>
                    <span>Project Management Expert</span>
                </div>
            </div>
            <div class="single-advisor-item">
                <div class="advisor-image">
                    <img src="assets/img/advisor/img5.jpg" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
                <div class="advisor-content">
                    <h3><a routerLink="/profile">Jassica Hische</a></h3>
                    <span>Illustrator Expert</span>
                </div>
            </div>
            <div class="single-advisor-item">
                <div class="advisor-image">
                    <img src="assets/img/advisor/img6.jpg" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
                <div class="advisor-content">
                    <h3><a routerLink="/profile">Alister Cock</a></h3>
                    <span>QA Project Expert</span>
                </div>
            </div>
            <div class="single-advisor-item">
                <div class="advisor-image">
                    <img src="assets/img/advisor/img7.jpg" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
                <div class="advisor-content">
                    <h3><a routerLink="/profile">Lina Ninja</a></h3>
                    <span>QA Project Expert</span>
                </div>
            </div>
        </div>
    </div>
</div>