import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
    userRole: any;
    isLoggedIn: any;
    actionBTN: string = 'LOGIN/REGISTER'
    el: any;
    isNavbarOpen = false;

    constructor(private auth: AuthService, private router: Router) { }

    goToHire() {
        this.router.navigateByUrl("/contact-us");
    }

    ngOnInit(): void {
        if (this.isLoggedIn = this.auth.isLoggedIn()) {
            this.actionBTN = 'LOGOUT'
        }

    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }
    onAction() {
        if (this.isLoggedIn) {
            this.auth.logout()
            this.router.navigateByUrl('profile-authentication/login')
        } else {
            this.router.navigateByUrl('profile-authentication/login')
        }
    }
  
    toggleNavbar() {
        this.isNavbarOpen = !this.isNavbarOpen;
      }
    
}
