import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TrainersService {
    courseAdvisor = [
        {
            id: '1',
            name: 'Mr.Dhanaji',
            // expertisee: 'C#.Net',
            current_position: 'Senior Software Engineer',
            desc: 'Currently working as a Senior Software Engineer in Wipro<br/> Overall 8 plus years of experience in Web and Product Development - .Net Technologies<br/> Prior to that had working experience with Capita and LNT Infotech<br/> Skill Set : Angular, Bootstrap, C# .Net, ASP .Net, .Net Core, Web API',
            image: 'assets/img/all-course-icon.png',
            instagram_link:
                'https://www.instagram.com/codemind_technology_official/',
            linkedIn_link:
                'https://in.linkedin.com/company/codemind-technology',
        },
        {
            id: '2',
            name: 'Mr.Dhananjay',
            // expertisee: 'C#.Net',
            current_position: 'Project Manager',
            desc: 'Currently working as a Project Manager in FIS Global<br/> Overall 11 plus years of experience in Web and Product Development - .Net full stack Developement<br/> Also currently managing team of 20 associates and working closely with clients for deliverables.<br/> Prior to that had working experience with Fiserv, Infosys and HSBC<br/> Skill Set : C# .Net, ASP .Net, MVC, WCF, .Net Core, Web API, Angular.',
            image: 'assets/img/all-course-icon.png',
            instagram_link:
                'https://www.instagram.com/codemind_technology_official/',
            linkedIn_link:
                'https://in.linkedin.com/company/codemind-technology',
        },
        {
            id: '3',
            name: 'Mr.Gajanan',
            // expertisee: 'Java',
            current_position: 'Technical Lead',
            desc: 'Currently working in HCL Technologies as a Technical Lead.<br/> Overall 10 plus years of experience in Web and Product Development - Full stack Developer,<br/> Also has 3 years onsite working experience in UK and US.<br/> Prior to that had working experience with LNT Infotech, Geometric and Persistent System<br/> Skill Set : Angular, Java, Spring Boot, Microservices, CICD, Jenkin, Docker, SQL, Oracle, My SQL',
            image: 'assets/img/all-course-icon.png',
            instagram_link:
                'https://www.instagram.com/codemind_technology_official/',
            linkedIn_link:
                'https://in.linkedin.com/company/codemind-technology',
        },
        {
            id: '4',
            name: 'Mr.Dnyaneshwar',
            // expertisee: 'QA Project Expert',
            current_position: 'Technical Consulatant',
            desc: 'Currently working as a Technical Consulatant in Hexaware<br/> Overall 9 plus years of experience in Web and DevOps Azure<br/> Prior to that had working experience with Alova Technology and Tech Mahindra<br/> Skill Set : DevOps, Azure, Angular, Ionic Framework, C# .Net, ASP .Net, .Net Core, Web API',
            image: 'assets/img/all-course-icon.png',
            instagram_link:
                'https://www.instagram.com/codemind_technology_official/',
            linkedIn_link:
                'https://in.linkedin.com/company/codemind-technology',
        },
        {
            id: '5',
            name: 'Mr.Chandan',
            // expertisee: 'Agile Project Expert',
            current_position: 'QA Lead',
            desc: 'Currently working in Synechron as a QA Lead.<br/> Around 9 years of experience in Manual and Automation Testing<br/> Prior to that had working experience with Barclays, Nice System, Aloha technology and Info vision lab<br/> Skill Set : Manual and Automation Testing, Selenium, Agile, JIRA, Gharkin, Cucumber, POM, CICD tools and Jenkins',
            image: 'assets/img/all-course-icon.png',
            instagram_link:
                'https://www.instagram.com/codemind_technology_official/',
            linkedIn_link:
                'https://in.linkedin.com/company/codemind-technology',
        },
        {
            id: '6',
            name: 'Mr.Sachin',
            // expertisee: 'C#.Net',
            current_position: 'Team Lead',
            desc: 'Currently working in LNT Infotech as a Team Lead.<br/> Around 9.5 years of experience in Java Technologies.<br/> Prior to that had working experience with Harman, Xoriant and Cognizant<br/> Skill Set : Java, Java 1.8, Hibernate, JPA, Spring Boot, Microservices, SQL and Apache Kafka',
            image: 'assets/img/all-course-icon.png',
            instagram_link:
                'https://www.instagram.com/codemind_technology_official/',
            linkedIn_link:
                'https://in.linkedin.com/company/codemind-technology',
        },
        {
            id: '7',
            name: 'Mr.Akash',
            // expertisee: 'C#.Net',
            current_position: 'Senior Software Engineer',
            desc: 'Currently working in LNT Infotech as a Team Lead.<br/> Around 9.5 years of experience in Java Technologies.<br/> Prior to that had working experience with Harman, Xoriant and Cognizant<br/> Skill Set : Java, Java 1.8, Hibernate, JPA, Spring Boot, Microservices, SQL and Apache Kafka',
            image: 'assets/img/all-course-icon.png',
            instagram_link:
                'https://www.instagram.com/codemind_technology_official/',
            linkedIn_link:
                'https://in.linkedin.com/company/codemind-technology',
        },
        {
            id: '8',
            name: 'Mrs.Shilpa',
            // expertisee: 'C#.Net',
            current_position: 'Senior Software Engineer',
            desc: 'Currently working in LNT Infotech as a Team Lead.<br/> Around 9.5 years of experience in Java Technologies.<br/> Prior to that had working experience with Harman, Xoriant and Cognizant<br/> Skill Set : DevOps, Azure, ReactJs, C# .Net, ASP .Net, .Net Core, Web API',
            image: 'assets/img/all-course-icon.png',
            instagram_link:
                'https://www.instagram.com/codemind_technology_official/',
            linkedIn_link:
                'https://in.linkedin.com/company/codemind-technology',
        },
    ];

    constructor() { }

    searhedTrainer(id: string) {
        return this.courseAdvisor.find((x) => x.id == id);
    }
}
