<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <img class="placement" src="assets/img/placementsection.jpg" alt="codemind-banner" />
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Our Placements</li>
            </ul>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>
<div class="container">
    <div class="section-title courses-info">
        <h2>Our Pleased Placements</h2>
        <p>
            <b>CODEMIND Technology</b> has a proven record of placing candidates as software developers. Their
            rigorous training
            programs equip students with the skills needed to excel in the industry. Graduates have secured software
            development positions in leading companies, leveraging their expertise in coding and problem-solving. Trust
            <b>CODEMIND Technology</b> to pave the way for your successful career as a software developer <a
                routerLink="/profile-authentication">Register Now!</a>
        </p>
    </div>
    <div class="row justify-content-center">
        <div class="col-lg-3 col-md-6" *ngFor="let candidate of allPlacedCandidate; let i=index">
            <div class="single-courses-box" [class.blur-background]="isDialogOpen">
                <div class="courses-image">
                    <h4 class="text-dark" [innerHTML]="candidate?.name"></h4>
                    <a class="d-block image">
                        <img [src]="candidate?.image" alt="candidate?.name">
                    </a>
                    <strong class="text-dark"
                        [innerHTML]="candidate?.designation + ' : ' + candidate?.package"></strong>
                </div>
                <div class="courses-content text-center">
                    <div class="PlacementsCard">
                        <div class="PlacementsForm">
                            <button>From</button>
                            <p [innerHTML]="candidate?.from"></p>
                        </div>
                        <div class="PlacementsCardLine"></div>
                        <div class="PlacementsCardTo">
                            <button>To</button>
                            <p [innerHTML]="candidate?.To"></p>
                        </div>
                    </div>
                    <!-- <h4><a class="text-dark" [innerHTML]="candidate?.company"></a>
                    </h4>-->
                    <div>
                        <img class="placed-company-img" [src]="candidate?.image2" />
                    </div>
                    <a class="read" (click)="openModal(candidate, i)">Read More</a>

                </div>
            </div>
            <div class="modal popup " tabindex="-1" role="dialog" [ngStyle]="{'display': display}">
                <div class="modal-dialog-center" role="document">
                    <div class="modal-content-center " style="height: 34vh;">
                        <div class="modal-header-center">
                            <h4 class="modal-title"> {{ allPlacedCandidate[selectedCandidateIndex]?.name }}</h4>
                        </div>
                        <div class="modal-body row align-items-center">
                            <div class="iconimg col-md-3">
                                <img [src]="allPlacedCandidate[selectedCandidateIndex]?.image" alt="">
                            </div>
                            <p class="col-md-9 align-items-center">
                                {{ allPlacedCandidate[selectedCandidateIndex]?.quote }}
                            </p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn-danger rounded" (click)="onCloseHandled()">Close</button>
                        </div>
                    </div>
                </div>