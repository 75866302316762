import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor() { }
  AllQuestions=[
    {
      question:'What will be The Duration Of Course?',
      Ans:'Ideally The Course is completed in 5 to 6 months we belive every student has diffrent grasping power so it differs as per student ability to grasp things'
    }
  ]
}
