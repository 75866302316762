<div mat-dialog-actions>
    <button mat-button (click)="closeDialog()"><i class="bx bx-x"></i></button>
  </div>
<h2 mat-dialog-title>Video Details</h2>
    <div mat-dialog-content>
      <video width="100%" height="100%" controls autoplay>
        <source [src]="data.videoSrc" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
   