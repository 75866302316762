import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class HiringPartnerService {
    partners = [
        {
            id: 1,
            image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b1/Tata_Consultancy_Services_Logo.svg/2560px-Tata_Consultancy_Services_Logo.svg.png',
            name: 'TCS',
        },
        {
            id: 2,
            image: 'https://www.infosys.com/content/dam/infosys-web/en/global-resource/media-resources/infosys-nyn-tagline-png.png',
            name: 'INFOSYS',
        },
        {
            id: 3,
            image: 'https://companieslogo.com/img/orig/WIT_BIG-0de2dc21.png?t=1648406781',
            name: 'WIPRO',
        },
        {
            id: 4,
            image: 'http://icodexsolutions.com/img/logo.svg',
            name: 'iCODEX',
        },
        {
            id: 5,
            image: 'https://www.hcltech.com/themes/custom/hcltech/images/hcltech-new-logo.svg',
            name: 'HCL',
        },
        {
            id: 6,
            image: 'https://files.techmahindra.com/static/img/brandkit/logo/Logo-True-Colors.png',
            name: 'TECH MAHINDRA',
        },
        {
            id: 7,
            image: 'http://3.bp.blogspot.com/-ungDy8ffHFs/UGd2GmAT7oI/AAAAAAAANjU/EwsRb_mXJQI/s1600/Mindtree+logo+2012.pngpng.png',
            name: 'MINDTREE',
        },
        {
            id: 8,
            image: 'https://www.cognizant.com/content/dam/connectedassets/cognizant-global-marketing/marketing-channels/cognizant-dotcom/en_us/logos/cognizant/COG-Logo-2022.svg',
            name: 'CONGIZANT',
        },
        {
            id: 9,
            image: 'https://prod.ucwe.capgemini.com/wp-content/themes/capgemini2020/assets/images/logo.svg',
            name: 'CAPGIMINI',
        },
        {
            id: 10,
            image: 'https://www.accenture.com/content/dam/accenture/final/images/icons/symbol/Acc_Logo_Black_Purple_RGB.png',
            name: 'ACCENTURE',
        },
        {
            id: 11,
            image: 'https://www.freepnglogos.com/uploads/ibm-logo-png/ibm-logo-lusha-lead-enrichment-click-direct-dials-and-13.png',
            name: 'IBM',
        },
        {
            id: 12,
            image: 'https://media.designrush.com/agencies/233901/conversions/Royal-Cheese-Digital-logo-profile.jpg',
            name: 'ROYLE CHEESE',
        },
        {
            id: 13,
            image: 'https://media.umbrellainfocare.com/wp-content/uploads/2020/05/Umbrella-Infocare-Logo.svg',
            name: 'UMBRELLA INFOCARE',
        },
        {
            id: 14,
            image: 'https://companieslogo.com/img/orig/DOX-148df523.png?t=1598836673',
            name: 'AMDOCS',
        },
        {
            id: 15,
            image: 'https://companieslogo.com/img/orig/ZENSARTECH.NS_BIG-943eb02e.png?t=1604053080',
            name: 'ZENSER',
        },
        {
            id: 16,
            image: 'https://www.mobisoft-me.com/images/mobisoft-01.png',
            name: 'MOBISOFT',
        },
        {
            id: 17,
            image: 'https://www.persistent.com/wp-content/uploads/2020/09/persistent-systems-header-logo.png',
            name: 'PERSISTENT SYSTEM',
        },
        {
            id: 18,
            image: 'https://www.ltts.com/themes/custom/lnttech/images/media_kit_logos/stacked/stacked.png',
            name: 'L&T',
        },
        {
            id: 19,
            image: 'https://static.theprint.in/wp-content/uploads/2023/08/ANI-20230812092819.jpg',
            name: 'ALLSTATE INDIA',
        },
        {
            id: 20,
            image: 'https://download.logo.wine/logo/Barclays/Barclays-Logo.wine.png',
            name: 'BARCLAYS',
        },
        // {
        //     id: 21,
        //     image: 'https://www.senwellsys.com/logo.png',
        //     name: 'SENWELL',
        // },
        {
            id: 22,
            image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Hexaware_new_logo.svg/1200px-Hexaware_new_logo.svg.png',
            name: 'HEXAWERE',
        },
        {
            id: 23,
            image: 'https://logos-world.net/wp-content/uploads/2020/09/Oracle-Symbol.png',
            name: 'ORACLRE',
        },
        // {
        //     id: 24,
        //     image: 'https://1000logos.net/wp-content/uploads/2020/08/Atos-Logo.png',
        //     name: 'ATOS',
        // },
        {
            id: 25,
            image: 'https://appexchange.salesforce.com/partners/servlet/servlet.FileDownload?file=00P4V000012vXhtUAE',
            name: 'ASSIMILATE',
        },
        {
            id: 26,
            image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/45/Birlasoft_logo.png/1200px-Birlasoft_logo.png',
            name: 'BIRLASOFT',
        },
        // {
        //     id: 27,
        //     image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSS3nflXoUDqXmwgSzBwLE7FZC2QdQ155UQ3O2xTl_Y_NvGpD2qR0ooxyODSZndFK2W_sM&usqp=CAU',
        //     name: 'ASPIRING MINDS',
        // },
        {
            id: 28,
            image: 'https://companieslogo.com/img/orig/WNS-f03012b2.png?t=1660042129',
            name: 'WNS',
        },
        {
            id: 29,
            image: 'https://solaceinfotech.com/images/logo.png',
            name: 'SOLACE INFOTECK',
        },
        {
            id: 30,
            image: 'https://eclerx.com/wp-content/uploads/2020/04/eClerx-Logo_Final.png',
            name: 'ECLERX',
        },
    ];
    constructor() { }
}
