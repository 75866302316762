<div class="cont">
    <div class="trainingcircal" (click)="goToTraining()">
        <div class="circle_inner  "></div>
        <div class=""></div>
        <div class="circle_content Training_circle_content">
            <div class="circle_last Training_circle_content_last"></div>
        </div>
        <div class="circle_border Training_circle_border">Training</div>
    </div>
    <div class="trainingcircal" (click)="goToPlacement()">
        <div class="circle_inner "></div>
        <div class=""></div>
        <div class="circle_content Placement_circle_content">
            <div class="circle_last Placement_circle_content_last"></div>
        </div>
        <div class="circle_border Placement_circle_border">Placement</div>
    </div>
    <div class="trainingcircal" (click)="goToContact()">
        <div class="circle_inner "></div>
        <div class=""></div>
        <div class="circle_content Contact_circle_content">
            <div class="circle_last Contact_circle_last"></div>
        </div>
        <div class="circle_border Contact_circle_border">Contact-Us</div>
    </div>
</div>