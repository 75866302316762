import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { LinkService } from 'src/app/services/link.service';

@Component({
    selector: 'app-coming-soon',
    templateUrl: './coming-soon.component.html',
    styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {
    randomColor: any
    newsLetterForm!: FormGroup;
    colors: any[] = ['#80ed99', '#80ed99', '#80ed99', '#80ed99', '#80ed99', '#80ed99'];

    animText = [
        'C',
        'O',
        'D',
        'E',
        'M',
        'I',
        'N',
        'D',
        '&nbsp',
        'T',
        'E',
        'C',
        'H',
        'N',
        'O',
        'L',
        'O',
        'G',
        'Y',
    ];
    days: any;
    hours: any;
    minutes: any;
    seconds: any;
    myInterval: any;

    constructor(
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private title: Title,
        private meta: Meta,
        private linkService: LinkService
    ) { }

    ngOnInit() {
        this.title.setTitle('Events | We Publish Our Next Move Here');
        this.meta.updateTag({ name: 'description', content: 'Be the part of our team and start your career with us' });
        this.linkService.addTag({ rel: 'canonical', href: 'http://codemindtechnology.com/events' })

        this.myInterval = setInterval(() => {
            this.commingSoonTime();
        }, 0);

        this.newsLetterForm = this.formBuilder.group({
            email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')])
        })

        let colorIndex = Math.floor(Math.random() * this.colors.length);
        this.randomColor = this.colors[colorIndex];
    }

    commingSoonTime = () => {
        const endTimeParse = (Date.parse('April 15, 2024 17:00:00 PDT')) / 1000;
        const now = new Date();
        const nowParse = (Date.parse(now.toString()) / 1000);
        const timeLeft = endTimeParse - nowParse;
        const days = Math.floor(timeLeft / 86400);
        let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
        let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60);
        let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
        if (hours < 10) { hours = 0 + hours; }
        if (minutes < 10) { minutes = 0 + minutes; }
        if (seconds < 10) { seconds = 0 + seconds; }
        this.days = days;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
    }

    onSubscribe(newsLetterForm: any) {
        this.newsLetterForm.reset();
        this.toastr.success("Response has been submitted successfully", 'SUCCESS')
    }

}
