<div class="container">
    <div class="section-title">
        <!--<span class="sub-title">Education for everyone</span>-->
        <h2>Our On Job Trainings​</h2>
        <p>
            Discover affordable online courses and diverse learning opportunities at <strong>CODEMIND
                Technology</strong> in Pune. Expand
            your skills, boost your knowledge, and advance your career without straining your budget. With competitive
            pricing and flexible payment options, we make quality education accessible to all. Unlock your potential
            with our affordable online courses today.
        </p>
    </div>
    <div class="feedback-slides-two">
        <div class="row">
            <div class="col-md-6" carouselSlide *ngFor="let course of featuredCourses">

                <div class="single-features-box">
                    <div class="icon">
                        <h3 [innerHTML]="course?.title"></h3>

                        <div class="list">
                            <ol>
                                <li>Duration 5 Months</li>
                                <li>Technologies you will learn</li>
                            </ol>
                        </div>
                    </div>
                    <div>
                    </div>
                    <div class="planet">
                        <div class="row first">
                            <div class="col-3">
                                <div class="item">
                                    <img class="logo" [src]="course?.logo1" />
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="item">
                                    <img class="logo" [src]="course?.logo2" />
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="item">
                                    <img class="logo" [src]="course?.logo3" />
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="item">
                                    <img class="logo" [src]="course?.image" />
                                </div>

                            </div>
                        </div>
                        <div class="row second">
                            <div class="col-4">
                                <div class="item">
                                    <img class="logo" [src]="course?.logo4" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="item">
                                    <img class="logo" [src]="course?.logo5" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="item">
                                    <img class="logo" [src]="course?.logo6" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="courseCard">
                        <button type="button" (click)="getCourseDetails(course)" [innerHTML]="course?.view">Learn
                            More</button>
                        <button type="button" (click)="downloadPDF(course?.syllabus)">Curriculam</button>
                    </div>

                    <!-- <div class="courseCard">
                        <a [routerLink]="['/featured-courses/course', course?.url]">
                            <button type="button" [innerHTML]="course?.view">Learn More</button>
                        </a>
                        <button type="button" (click)="downloadPDF(course?.syllabus)">Curriculum</button>
                    </div> -->
                    
                </div>
            </div>
        </div>

    </div>

</div>