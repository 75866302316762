<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Courses</a></li>
                <li [innerHTML]="courseDetail?.title"></li>
            </ul>
            <h2 [innerHTML]="courseDetail?.title"></h2>
            <div class="rating">
                <i class="bx bxs-star"></i>
                <i class="bx bxs-star"></i>
                <i class="bx bxs-star"></i>
                <i class="bx bxs-star"></i>
                <i class="bx bxs-star"></i>
                <div class="rating-count">
                    <span>4.9 (4.9 rating)</span>
                </div>
            </div>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>

<div class="courses-details-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="courses-details-desc">
                    <ngx-tabset>
                        <ngx-tab tabTitle="Overview" *ngIf="courseDetail?.title !== 'DEVOPS'">
                            <div class="courses-overview">
                                <ul class="acco">
                                    <li>
                                        <input type="radio" name="acco" id="first">
                                        <label for="first" (click)="toggleTag('first')" class="label1"
                                            [innerHTML]="courseDetail?.course?.namehtml?.type">
                                        </label>
                                        <div class="content" *ngIf="showme === 'first' ">
                                            <p [innerHTML]="
                                    courseDetail?.course
                                        ?.namehtml?.subpoints"> </p>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="acco">
                                    <li>
                                        <input type="radio" name="acco" id="second">
                                        <label class="label1" for="second" (click)="toggleTag('second')"
                                            [innerHTML]="courseDetail?.course?.namecss?.type"></label>
                                        <div class="content" *ngIf="showme ==='second'">
                                            <p [innerHTML]="courseDetail?.course?.namecss?.subpoints"></p>
                                        </div>
                                    </li>
                                </ul>

                                <ul class="acco">
                                    <li>
                                        <input type="radio" name="acco" id="third">
                                        <label for="third" class="label1" (click)="toggleTag('third')"
                                            [innerHTML]="courseDetail?.course?.namejavascript?.type"></label>
                                        <div class="content" *ngIf="showme ==='third'">
                                            <p [innerHTML]="courseDetail?.course?.namejavascript?.subpoints"></p>
                                        </div>
                                    </li>
                                </ul>


                                <ul class="acco">
                                    <li>
                                        <input type="radio" name="acco" id="four">
                                        <label for="four" class="label1" (click)="toggleTag('four')"
                                            [innerHTML]="courseDetail?.course?.nameangular?.type"></label>
                                        <div class="content" *ngIf="showme === 'four'">
                                            <p [innerHTML]="courseDetail?.course?.nameangular?.subpoints"></p>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Eligibility">
                            <div class="courses-curriculum">
                                <h3 class="minimum">Minimum Criteria</h3>
                                <div class="row">
                                    <div class="card p-2 m-2" style="width: 17rem">
                                        <div class="card-header">
                                            <h3>Qualification</h3>
                                        </div>
                                        <div class="card-body">
                                            <p [innerHTML]="
                                                    courseDetail?.criteria
                                                        ?.qualification
                                                "></p>
                                        </div>
                                    </div>
                                    <div class="card p-2 m-2" style="width: 18rem">
                                        <div class="card-header">
                                            <h3>Hardware</h3>
                                        </div>
                                        <div class="card-body">
                                            <p [innerHTML]="
                                                    courseDetail?.criteria
                                                        ?.hardware
                                                "></p>
                                        </div>
                                    </div>
                                    <div class="card p-2 m-2" style="width: 18rem">
                                        <div class="card-header">
                                            <h3>Communication</h3>
                                        </div>
                                        <div class="card-body">
                                            <p [innerHTML]="
                                                    courseDetail?.criteria
                                                        ?.communication
                                                "></p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </ngx-tab>

                        <ngx-tab tabTitle="Trainer">
                            <div class="courses-instructor">
                                <div class="single-advisor-box">
                                    <div class="row align-items-center">
                                        <div class="col-lg-4 col-md-4">
                                            <div class="advisor-image mt-5">
                                                <img [src]="
                                                        courseDetail?.trainer
                                                            ?.image
                                                    " [alt]="
                                                        courseDetail?.trainer
                                                            ?.name
                                                    " />
                                            </div>
                                        </div>
                                        <div class="col-lg-8 col-md-8 mt-5">
                                            <div class="advisor-content">
                                                <h3 [innerHTML]="
                                                        courseDetail?.trainer
                                                            ?.name
                                                    "></h3>
                                                <span class="sub-title" [innerHTML]="
                                                        courseDetail?.trainer
                                                            ?.current_position
                                                    "></span>
                                                <p [innerHTML]="
                                                        courseDetail?.trainer
                                                            ?.desc
                                                    "></p>
                                                <ul class="social-link">
                                                    <li>
                                                        <a href="#" class="d-block" target="_blank"><i
                                                                class="bx bxl-facebook"></i></a>
                                                    </li>
                                                    <li>
                                                        <a href="#" class="d-block" target="_blank"><i
                                                                class="bx bxl-twitter"></i></a>
                                                    </li>
                                                    <li>
                                                        <a href="#" class="d-block" target="_blank"><i
                                                                class="bx bxl-instagram"></i></a>
                                                    </li>
                                                    <li>
                                                        <a href="#" class="d-block" target="_blank"><i
                                                                class="bx bxl-linkedin"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ngx-tab>

                    </ngx-tabset>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="courses-details-info">
                    <div class="image">
                        <div>
                            <img [src]="courseDetail?.course_banner" [alt]="courseDetail?.title" />
                        </div>
                    </div>
                    <div class="courses-share">

                        <div class="btn-box mb-4">
                            <button class="default-btn" (click)="downloadPDF(courseDetail?.syllabus)">Download
                                Syllabus</button>
                        </div>
                        <div class="share-info">
                            <span>Share This Course <i class="flaticon-share"></i></span>
                            <ul class="social-link">
                                <li>
                                    <a href="https://www.facebook.com/login.php" class="d-block" target="_blank"><i
                                            class="bx bxl-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com" class="d-block" target="_blank"><i
                                            class="bx bxl-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com" class="d-block" target="_blank"><i
                                            class="bx bxl-instagram"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com" class="d-block" target="_blank"><i
                                            class="bx bxl-linkedin"></i></a>
                                </li>
                            </ul>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>