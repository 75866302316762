import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router,NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { CoursesService } from 'src/app/services/courses.service';
import { LinkService } from 'src/app/services/link.service';

@Component({
    selector: 'app-courses-style-one',
    templateUrl: './courses-style-one.component.html',
    styleUrls: ['./courses-style-one.component.scss'],
})
export class CoursesStyleOneComponent implements OnInit {
    featuredCourses: any;
    randomColor: any;
    colors: any[] = ['#80ed99', '#80ed99', '#80ed99', '#80ed99', '#80ed99', '#80ed99'];

    constructor(
        private courseService: CoursesService,
        private router: Router,
        private title: Title,
        private meta: Meta,
        private linkService: LinkService,
        private auth: AuthService,
        private toastr: ToastrService,
    ) {
        // this.router.events.subscribe((event) => {
        //     if (event instanceof NavigationEnd) {
        //       window.scrollTo(0, 0);
        //     }
        //   });
     }

    ngOnInit(): void {
        this.title.setTitle('Front End | Back End | Full Stack | Web Development | Mobile app Development | Seleniun & Automation Testing | DevOps');
        this.meta.updateTag({ name: 'description', content: 'Learn front-end and full-stack development from industry experts with our comprehensive courses. Gain the skills and knowledge needed to build responsive, dynamic web applications.' });
        this.linkService.addTag({ rel: 'canonical', href: 'http://codemindtechnology.com/featured-courses' })
        this.featuredCourses = this.courseService.allCourses;
        let colorIndex = Math.floor(Math.random() * this.colors.length);
        this.randomColor = this.colors[colorIndex];
    }
    getDetalis(courseName: string) {
        let mystring = courseName.toLowerCase();
        let arr: Array<any> = mystring.split(' ', 2);
        let firstWord = arr[0];
        this.router.navigateByUrl(`course/${firstWord}`);
  
    }
    courseDetail(course: any) {
        this.router.navigateByUrl(`featured-courses/course/${course?.url}`,{ skipLocationChange: true });
        window.scrollTo(0,0);
    }

    searchOperator(opr: any) {
        let input = opr?.value.toLowerCase();
        let x: any = document.getElementsByClassName('searchFilter');
        for (let index = 0; index < x.length; index++) {
            if (!x[index].innerHTML.toLowerCase().includes(input)) {
                x[index].style.display = "none"
            } else {
                x[index].style.display = 'inline'
            }
        }

    }

    getCourseDetails(course: any) {
        this.router.navigateByUrl(`/featured-courses/course/${course?.url}`);
        // window.scrollTo(0,0);
    }

    downloadPDF(url: string) {
        // if (this.auth.isLoggedIn()) {
            window.open(url, '_blank');
        // } else {
        //     this.toastr.warning('You have to logged in to download the syllabus', 'WARNING');
        //     this.router.navigateByUrl('profile-authentication/login')
        // }
    }
}
