<app-navbar *ngIf="!(location == '/coming-soon')"></app-navbar>
<!-- prompt-box.component.html -->
<div *ngIf="showPrompt" class="prompt-box-container">
    <div class="prompt-box">
        <div class="content-container">
            <button
                class="btn-close close-button"
                (click)="closePrompt()"
            ></button>
            <app-prompt-box></app-prompt-box>
        </div>
    </div>
</div>
<div class="whatspp">

    <a href="https://wa.me/919665044698" target="_blank"> <img src="/assets/img/whaspp.png" width="70px" class="whatspp-btn"></a>
 </div>
<router-outlet></router-outlet>
<app-footer *ngIf="!(location == '/coming-soon')"></app-footer>
<ngx-scrolltop></ngx-scrolltop>
