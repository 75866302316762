import { Component, OnInit} from '@angular/core'
import { Meta, Title } from '@angular/platform-browser';
import { LinkService } from 'src/app/services/link.service';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { ContactService } from 'src/app/services/contact.service';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
    firstFormGroup!: FormGroup;

    constructor(private title: Title,
        private meta: Meta,
        private linkService: LinkService,
        private _formBuilder: FormBuilder,
        private contactService: ContactService) { }

    ngOnInit(): void {
        this.title.setTitle('Contact Us | Get in Touch with Our Team');
        this.meta.updateTag({ name: 'description', content: 'Need to get in touch with our team? Our contact us page provides all the information you need to connect with us and learn more about our services.' })
        this.linkService.addTag({ rel: 'canonical', href: 'http://codemindtechnology.com/contact-us' });
      
        this.firstFormGroup = this._formBuilder.group({
            fullName: new FormControl('', [Validators.required]),
            mobile: new FormControl('', [Validators.required, Validators.maxLength(10)]),
            email: new FormControl('', [Validators.required, Validators.email,
                Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
            ]),
            courses: new FormControl('', [Validators.required]),
          });

    }

    /*getContactUsFormValue(){
     this.contactService.sendFormData(this.firstFormGroup.value)
     .subscribe(
      response => {
        console.log('API Response:', response);
        alert('Form submitted successfully!');
      },
      error => {
        console.error('API Error:', error);
        alert('Failed to submit form. Please try again.');
       alert('Form submitted successfully!');
       this.firstFormGroup.reset();
      }
    );
    }*/
    getContactUsFormValue() {
      const formValue = this.firstFormGroup.value;
    
      const apiPayload = {
        BranchKey: "E39C1D2D-B060-40BD-A181-0AC00D5F96E2",
        StudentName: formValue.fullName,
        Mobile: formValue.mobile,
        Email: formValue.email,
        GradeName: formValue.courses, 
       GradeId:this.getGradeIdByName(formValue.courses),
       
      };
      console.log('API Payload:', apiPayload);
  
      this.contactService.sendFormData(apiPayload)
        .subscribe(
          response => {
            console.log('API Response:', response);
            alert('Form submitted successfully!');
            this.firstFormGroup.reset();
           
          },
          error => {
            console.error('API Error:', error);
            alert('Failed to submit form. Please try again.');
            
          }
        );
      
    }
    getGradeIdByName(gradeName: string): number  {
   
      const grades = [
        { id: 58021, name: 'Angular' },
        { id: 59865, name: 'Campus connect' },
        { id: 59158, name: 'Data Science' },
        { id: 59027, name: 'DevOps' },
        { id: 59026, name: 'Dot Net' },
        { id: 57984, name: 'Java' },
        { id: 58023, name: 'Manual And Automation Testing' },
        { id: 58022, name: 'Python/Data Science' },
        { id: 58024, name: 'React' },
        
        
      ];
    
      const selectedGrade = grades.find(grade => grade.name === gradeName);
      return selectedGrade ? selectedGrade.id : 0; // Return 0 if not found, adjust accordingly
    }
  
    
}
