import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CoursesService {
    coursebyId: any;

    allCourses = [
        {
            id: '1',
            url: 'angular',
            image: 'assets/img/courses/Angular.png',
            logo1:'assets/img/courses/angularTech/html.png',
            logo2:'assets/img/courses/angularTech/csslogo.png',
            logo3:'assets/img/courses/angularTech/javacript.png',
            logo4:'assets/img/courses/angularTech/Bootstrap2.png',
            logo5:'assets/img/courses/angularTech/Typescript.png',
            logo6:'assets/img/courses/angularTech/mongodb.png',
            duration:'Duration 5 months',
            course_banner:'assets/img/course-details/angular-banner.png',
            title: 'ANGULAR',
        
            desc: 'Angular is an open-source, JavaScript framework written in TypeScript. Google maintains it, and its primary purpose is to develop single-page applications. As a framework, Angular has clear advantages while also providing a standard structure for developers to work with. It enables users to create large applications in a maintainable manner.',
            view: 'View More',
            syllabus:
                'assets/syllabus/Syllabus - Complete Angular with HTML CSS and JavaScript.pdf',
            fee: '20K',
            points: '<ol><li> HTML+CSS : Project Development</li><li> GIT+GitHub : SCM Tool</li><li> Bootstrap : Responsive Design </li><li> JavaScript : ES6 </li><li> Agile+Jira : Scrum Issue tracking </li><li> Typescript : In Depth+ OOPS</li><li> Angular : Go Live Projects</li></ol>',
            trainer: {
                name: 'Mr.Danny S',
                image: 'assets/img/all-course-icon.png',
                desc: 'Currently working as a Technical Consulatant in Hexaware<br/> Overall 9 plus years of experience in Web and DevOps Azure<br/> Prior to that had working experience with Alova Technology and Tech Mahindra<br/> Skill Set : DevOps, Azure, Angular, Ionic Framework, C# .Net, ASP .Net, .Net Core, Web API',
                current_position: 'Technical Consulatant',
            },
            course: {
                desc: 'For a duration of 15 weeks, the course requires a commitment from 7AM - 8:30AM on weekdays, and 11AM-5PM on Saturday in an intensive and immersive curriculum.',
                duration: '15 Week (4 Months)',
                time: '7AM - 8:30AM week days<br> 11AM - 5PM Saturday',
                placement: '99.05% Pleacements',
                namehtml:{
                type:'HTML',
                subpoints:'<ol><li> Syllbus walk through</li><li>Installation and Basics</li><li>Basic Tags and DOM Intro</li><li>List and Table</li><li>Fontawesome and Links</li><li>HTML Form</li><li>Inline and block elements</li><li>Media tags</li><li>iFrame </li><li>Website Developement</li></ol>'
                },
               namecss:{
                 type:'CSS',
                 subpoints:'<ol><li>CSS Basics and Properties</li><li>Selectors</li><li>Margin and Padding</li><li>Google font family</li><li>CSS Box Model and Box sizing</li><li>CSS Units</li><li>Display and positioning</li><li>Overflow</li><li>Flexbox</li></ol>'
                },
                namejavascript:{
                    type:'JAVASCRIPT',
                    subpoints:'<ol><li>Basics, Variable & Data types</li><li>Function</li><li>String</li><li>Operators</li><li>Control Flow,All 7 loops</li><li>Varibale Scopes</li><li>Array, Set and Map</li><li>Object, class</li><li>Function Constructor</li><li>Prototype,Memory Management</li><li>Shallow Clone and Deep Clone</li><li>Hoisting ,JSON</li><li>Closure and Callbacks</li><li>Webstorage</li><li>ES6 Features</li><li>Advance Functions</li><li>Async and await</li><li>DOM</li>'
                },
                nameangular:{
                    type:'ANGULAR',
                    subpoints:'<ol><li>"Angular Creating and Communicating Between Components</li><li>"Angular</li><li>Angula Directives</li><li>Angular Collecting Data with Forms and Validation </li><li>Angular Service and Dependency Injection </li><li>Angular Pipes</li><li>Angular Interceptor</li><li>Angular RXJS</li><ol>'
                }
            },
            criteria: {
                qualification:
                    'BE, B.TECH, BCA, BCS, B.SC, MCA, MCS, MSC Also any graduate who can understand the English Language',
                hardware: 'A laptop, Good & stable internet connection',
                communication: 'Basic English - speaking, reading and writing',
                mental: 'You should learn the fundamentals of programming, which is just what you needed as a first step toward your career change',
            },
            what_to_learn: {
                course: [
                    'assets/img/course-details/html.svg',
                    'assets/img/course-details/css.svg',
                    'assets/img/course-details/bootstrap.png',
                    'assets/img/course-details/javascript.svg',
                    'assets/img/course-details/typescript.png',
                    'assets/img/course-details/angular.png',
                ],
                session_one: 'HTML, CSS & DOM, Bootstrap',
                session_two:
                    'Programming Basics in JavaScript, Implementation, Brute-force, String, Array, Loops, Key-Value Pairs, Time & Space Complexity, Sorting Algorithms and Typescript basic',
                session_three:
                    'Typescript Advanced and Angular Basics, Angular Advanced, Frontend Project, Servers, Project',
                session_four: 'Real Time Project',
            },
        },
        {
            id: '2',
            url: 'react-js',
            image: 'assets/img/courses/React-icon.svg.png',
            logo1:'assets/img/courses/angularTech/html.png',
            logo2:'assets/img/courses/angularTech/csslogo.png',
            logo3:'assets/img/courses/angularTech/javacript.png',
            logo4:'assets/img/courses/angularTech/Bootstrap2.png',
            logo5:'assets/img/courses/angularTech/Typescript.png',
            logo6:'assets/img/courses/angularTech/mongodb.png',
            duration:'Duration 5 months',
            course_banner: 'assets/img/course-details/react-banner.png',
            title: 'REACT JS',
            desc: "The React. js framework is an open-source JavaScript framework and library developed by Facebook. It's used for building interactive user interfaces and web applications quickly and efficiently with significantly less code than you would with vanilla JavaScript.",
            view: 'View More',
            syllabus:
                'assets/syllabus/Syllabus - Complete ReactJS with HTML CSS and JavaScript .pdf',
            fee: '20K',
            points: '<ol><li> HTML+CSS : Project Development</li><li> GIT+GitHub : SCM Tool</li><li> Bootstrap : Responsive Design </li><li> JavaScript : ES6 </li><li> Agile+Jira : Scrum Issue tracking </li><li> Typescript : In Depth+ OOPS</li><li> React : Go Live Projects</li></ol>',
            trainer: {
                name: 'Mrs.Shilpa P',
                image: 'assets/img/all-course-icon.png',
                current_position: 'Senior Software Engineer',
                desc: 'Currently working in LNT Infotech as a Team Lead.<br/> Around 9.5 years of experience in Java Technologies.<br/> Prior to that had working experience with Harman, Xoriant and Cognizant<br/> Skill Set : DevOps, Azure, ReactJs, C# .Net, ASP .Net, .Net Core, Web API',
            },
            course: {
                desc: 'For a duration of 15 weeks, the course requires a commitment from 7AM - 8:30AM on weekdays, and 11AM-5PM on Saturday in an intensive and immersive curriculum.',
                duration: '15 Week (4 Months)',
                time: '7AM - 8:30AM week days<br> 11AM - 5PM Saturday',
                placement: '96.% Pleacements',
                namehtml:{
                    type:'HTML',
                    subpoints:'<ol><li> Syllbus walk through</li><li>Installation and Basics</li><li>Basic Tags and DOM Intro</li><li>List and Table</li><li>Fontawesome and Links</li><li>HTML Form</li><li>Inline and block elements</li><li>Media tags</li><li>iFrame </li><li>Website Developement</li></ol>'
            
                    },
                   namecss:{
                     type:'CSS',
                     subpoints:'<ol><li>CSS Basics and Properties</li><li>Selectors</li><li>Margin and Padding</li><li>Google font family</li><li>CSS Box Model and Box sizing</li><li>CSS Units</li><li>Display and positioning</li><li>Overflow</li><li>Flexbox</li></ol>'
                    },
                    namejavascript:{
                        type:'JAVASCRIPT',
                        subpoints:'<ol><li>Basics, Variable & Data types</li><li>Function</li><li>String</li><li>Operators</li><li>Control Flow,All 7 loops</li><li>Varibale Scopes</li><li>Array, Set and Map</li><li>Object, class</li><li>Function Constructor</li><li>Prototype,Memory Management</li><li>Shallow Clone and Deep Clone</li><li>Hoisting ,JSON</li><li>Closure and Callbacks</li><li>Webstorage</li><li>ES6 Features</li><hr><li>Advance Functions</li><li>Async and await</li><li>DOM</li>'
                    },
                    nameangular:{
                        type:'REACT',
                        subpoints:'<ol><li>Prerequisite to learn React</li><li>Introduction to React</li><li>Installation and Project Structure</li><li>Understand folder structure and UI Composition</li><li>React component in detail</li><li>JSX and props</li><li>Routing using react router dom</li><li>Component communication</li><li>State in React</li><li>Conditional rendering and styling react comp</li><li>Component life cycle</li><li>Api integration</li><li>useEffect hook</li><li>Pure comp and Child to Parent data communication</li><li>Form handling in react</li><li>Advance form handling in react</li><li>Ref and useRef in react, Prop drilling</li><li>useContext hook</li><li>memo and useMemo</li><li>useReducer and useCallback hook</li><li>Controlled comp and Uncontrolled comp, Apex  chart</li><li>State management</li><li>CRUD operation using context api</li><ol>'
                    }
            },
            criteria: {
                qualification:
                    'BE, B.TECH, BCA, BCS, B.SC, MCA, MCS, MSC Also any graduate who can understand the English Language',
                hardware: 'A laptop, Good & stable internet connection',
                communication: 'Basic English - speaking, reading and writing',
                mental: 'You should learn the fundamentals of programming, which is just what you needed as a first step toward your career change',
            },
            what_to_learn: {
                course: [
                    'assets/img/course-details/html.svg',
                    'assets/img/course-details/css.svg',
                    'assets/img/course-details/bootstrap.png',
                    'assets/img/course-details/javascript.svg',
                    'assets/img/course-details/typescript.png',
                    'assets/img/course-details/angular.png',
                ],
                session_one: 'HTML, CSS & DOM, Bootstrap',
                session_two:
                    'Programming Basics in JavaScript, Implementation, Brute-force, String, Array, Loops, Key-Value Pairs, Time & Space Complexity, Sorting Algorithms and Typescript basic',
                session_three:
                    'Typescript Advanced and Angular Basics, Angular Advanced, Frontend Project, Servers, Project',
                session_four: 'Real Time Project',
            },
        },
        {
            id: '3',
            url: 'java-full-stack',
            image: 'assets/img/courses/Java.png',
            logo1:'assets/img/courses/angularTech/github.png',
            logo2:'assets/img/courses/angularTech/springboot.png',
            logo3:'assets/img/courses/angularTech/jira.png',
            logo4:'assets/img/courses/angularTech/orm.png',
            logo5:'assets/img/courses/angularTech/agile.png',
            logo6:'assets/img/courses/angularTech/java2.png',
            duration:'Duration 5 months',
            course_banner: 'assets/img/course-details/java-banner.png',
            title: 'JAVA FULL STACK',
            desc: 'Java is a multi-platform, object-oriented, and network-centric language. It is among the most used programming language. Java is also used as a computing platform.It is considered as one of the fast, secure, and reliable programming languages preferred by most organizations to build their projects.',
            view: 'View More',
            syllabus:
                'assets/syllabus/Syllabus -Java_SpringBoot_REST Services_Hibernate.pdf',
            fee: '20K',
            points: '<ol><li> Core Java : Project Development</li><li> GIT+GitHub : SCM Tool</li><li> Spring : MVC Design Pattern </li><li> Spring Boot : MicroService Development </li><li> Agile+Jira : Scrum Issue tracking </li><li> ORM Tools : DB+JPA+Hibernate</li><li> Go Live Projects</li></ol>',
            trainer: {
                name: 'Mr.Sachin M',
                image: 'assets/img/all-course-icon.png',
                current_position: 'Team Lead',
                desc: 'Currently working in LNT Infotech as a Team Lead.<br/> Around 9.5 years of experience in Java Technologies.<br/> Prior to that had working experience with Harman, Xoriant and Cognizant<br/> Skill Set : Java, Java 1.8, Hibernate, JPA, Spring Boot, Microservices, SQL and Apache Kafka',
            },
            course: {
                desc: 'For a duration of 15 weeks, the course requires a commitment from 7AM - 8:30AM on weekdays, and 11AM-5PM on Saturday in an intensive and immersive curriculum.',
                duration: '15 Week (4 Months)',
                time: '7AM - 8:30AM week days<br> 11AM - 5PM Saturday',
                placement: '89.95% Pleacements',
                namehtml:{
                    type:'CORE JAVA',
                    subpoints:'<ol><li>java introduction</li><li>Basic program construction</li><li>Oops</li><li>Core Java project and keywords</li><li>String</li><li>Exeption Handling</li><li>Collection Framework</li><li>Multi threading</li></ol>'
                    },
                   namecss:{
                     type:'ADVANCE JAVA',
                     subpoints:'<ol><li>Advance java</li><li>sevlet JSP</li><li>Spring Intoduction</li></ol>'
                    },
                    namejavascript:{
                        type:'SPRING BOOT',
                        subpoints:'<ol><li>Spring Introduction</li><li>Spring core basic concepts</li><li>RESTFULL web services</li><li>Spring Boot</li><li>Spring data JPA</li><li>Spring Security and Swagger</li><li>Microsoft services</li><ol>'
                    },
                    nameangular:{
                        type:'ORM TOOLS',
                        subpoints:'<ol><li>Introduction</li><li>Architecture</li><li>Configuration and object mapping</li><li>Object States</li><li>To communication with DB</li><li>Mechanism and isolation levels</li><li>Cache Mechanism</li><li>Mapping relationship</li><li>Inheritancet</li></ol>'
                    }
            },
            criteria: {
                qualification:
                    'BE, B.TECH, BCA, BCS, B.SC, MCA, MCS, MSC Also any graduate who can understand the English Language',
                hardware: 'A laptop, Good & stable internet connection',
                communication: 'Basic English - speaking, reading and writing',
                mental: 'You should learn the fundamentals of programming, which is just what you needed as a first step toward your career change',
            },
            what_to_learn: {
                course: [
                    'assets/img/course-details/html.svg',
                    'assets/img/course-details/css.svg',
                    'assets/img/course-details/bootstrap.png',
                    'assets/img/course-details/javascript.svg',
                    'assets/img/course-details/typescript.png',
                    'assets/img/course-details/angular.png',
                ],
                session_one: 'HTML, CSS & DOM, Bootstrap',
                session_two:
                    'Programming Basics in JavaScript, Implementation, Brute-force, String, Array, Loops, Key-Value Pairs, Time & Space Complexity, Sorting Algorithms and Typescript basic',
                session_three:
                    'Typescript Advanced and Angular Basics, Angular Advanced, Frontend Project, Servers, Project',
                session_four: 'Real Time Project',
            },
        },
       /* {
            id: '4',
            url: 'data-science-with-python',
            image: 'assets/img/courses/python.png',
            course_banner: 'assets/img/course-details/python-banner.png',
            title: 'DATA SCIENCE WITH PYTHON',
            desc: 'Data science is the field of study that combines domain expertise, programming skills, and knowledge of mathematics and statistics to extract meaningful insights from data.',
            view: 'View More',
            syllabus: 'assets/syllabus/Syllabus - Data Science.pdf',
            fee: '20K',
            points: '<ol><li> Python : Project Development</li><li> GIT+GitHub : SCM Tool</li><li> Python DB :  MySQL Database </li><li> REST : API Development </li><li> Django : All Django Modules </li><li> Agile+Jira : Scrum Issue tracking </li><li> Go Live Projects</li></ol>',
            trainer: {
                name: 'Mr.gajanan M',
                image: 'assets/img/all-course-icon.png',
                current_position: 'Technical Lead',
                desc: 'Currently working in HCL Technologies as a Technical Lead.<br/> Overall 10 plus years of experience in Web and Product Development - Full stack Developer,<br/> Also has 3 years onsite working experience in UK and US.<br/> Prior to that had working experience with LNT Infotech, Geometric and Persistent System<br/> Skill Set : Angular, Java, Spring Boot, Microservices, CICD, Jenkin, Docker, SQL, Oracle, My SQL',
            },
            course: {
                desc: 'For a duration of 15 weeks, the course requires a commitment from 7AM - 8:30AM on weekdays, and 11AM-5PM on Saturday in an intensive and immersive curriculum.',
                duration: '15 Week (4 Months)',
                time: '7AM - 8:30AM week days<br> 11AM - 5PM Saturday',
                placement: '94.00% Pleacements',
            },
            criteria: {
                qualification:
                    'BE, B.TECH, BCA, BCS, B.SC, MCA, MCS, MSC Also any graduate who can understand the English Language',
                hardware: 'A laptop, Good & stable internet connection',
                communication: 'Basic English - speaking, reading and writing',
                mental: 'You should learn the fundamentals of programming, which is just what you needed as a first step toward your career change',
            },
            what_to_learn: {
                course: [
                    'assets/img/course-details/html.svg',
                    'assets/img/course-details/css.svg',
                    'assets/img/course-details/bootstrap.png',
                    'assets/img/course-details/javascript.svg',
                    'assets/img/course-details/typescript.png',
                    'assets/img/course-details/angular.png',
                ],
                session_one: 'HTML, CSS & DOM, Bootstrap',
                session_two:
                    'Programming Basics in JavaScript, Implementation, Brute-force, String, Array, Loops, Key-Value Pairs, Time & Space Complexity, Sorting Algorithms and Typescript basic',
                session_three:
                    'Typescript Advanced and Angular Basics, Angular Advanced, Frontend Project, Servers, Project',
                session_four: 'Real Time Project',
            },
        },*/
        {
            id: '5',
            url: 'manual-and-selenium-automation-testing',
            image: 'assets/img/courses/manual-testing.png',
            logo1:'assets/img/courses/angularTech/corejava.png',
            logo2:'assets/img/courses/angularTech/postman.png',
            logo3:'assets/img/courses/angularTech/cucumber.png',
            logo4:'assets/img/courses/angularTech/seleniam.png',
            logo5:'assets/img/courses/angularTech/jenkins.png',
            logo6:'assets/img/courses/angularTech/github.png',
            duration:'Duration 5 months',
            course_banner: 'assets/img/course-details/testing-banner.png',
            title: 'MANUAL AND SELENIUM AUTOMATION TESTING',
            desc: 'Selenium is an open-source, automated, and valuable testing tool that all web application developers should be well aware of. A test performed using Selenium is usually referred to as Selenium automation testing',
            view: 'View More',
            syllabus:
                'assets/syllabus/Manual and Selenium Automation Testing  Syllabus -Codemind.pdf',
            fee: '20K',
            points: '<ol><li> Manual testing | Database | Core Java</li><li> API testing using Postman </li><li> Cucumber Framework Overview</li><li> Selenium POM model framework Overview | Selenium Automation</li><li>Maven | Jira+Agile</li><li>Jenkins | testNG</li></ol>',
            trainer: {
                name: 'Mr.Chandan J',
                image: 'assets/img/all-course-icon.png',
                current_position: 'QA Lead',
                desc: 'Currently working in Synechron as a QA Lead.<br/> Around 9 years of experience in Manual and Automation Testing<br/> Prior to that had working experience with Barclays, Nice System, Aloha technology and Info vision lab<br/> Skill Set : Manual and Automation Testing, Selenium, Agile, JIRA, Gharkin, Cucumber, POM, CICD tools and Jenkins',
            },
            course: {
                desc: 'For a duration of 15 weeks, the course requires a commitment from 7AM - 8:30AM on weekdays, and 11AM-5PM on Saturday in an intensive and immersive curriculum.',
                duration: '15 Week (4 Months)',
                time: '7AM - 8:30AM week days<br> 11AM - 5PM Saturday',
                placement: '90.00% Pleacements',
                namehtml:{
                type:'MANUAL TESTING',
                subpoints:'<ol><li>Basics of testing</li><li>Software deployement life cycle</li><li>Software Testing Types </li><li>Test Management tools</li><li>Defect Tracking management Tools</li><li>Api Testing using postman tools</li><li>Database</li></ol>'
                },
               namecss:{
                 type:'AUTOMATION TESTING',
                 subpoints:'<ol><li>Selenium Framework Setup</li><li>What is Selenium </li><li>What is Automation testing ? Advantages of Automation testing</li><li>Selenium OS and Browsers supported by Selenium</li><li>Java-Selenium Architecture</li><li>Web Driver Architecture</li><li>Basic selenium Program</li><li>Web driver Abstract methods</li><li>Selenium Locaters</li><li>Handling various Form Elements</li><li>Reporting Tool - JUnit, TestNG</li></ol>'
                },
                namejavascript:{
                    type:'CORE JAVA',
                    subpoints:'<ol><li>java introduction</li><li>Basic program construction</li><li>Oops</li><li>Core Java project and keywords</li><li>String</li><li>Exeption Handling</li><li>Collection Framework</li><li>Multi threading</li></ol>'
                },
                nameangular:{
                    type:'DATABASE',
                    subpoints:'<ol><li>Introduction to Database</li><li>What is SQL & SQL Commands</li><li>Datatypes in SQL</li><li>How to Create table in Database</li><li>How to Insert record into Database</li><li>Introduction to Select, Update and Delete statement</li><li>Introduction to Primary Key, Foreign Key, Composite Key</li><li>Introduction to AND, OR and NOT Operater</li><li>Introduction to SQL Functions</li><li>Introduction to Joins in SQL & Types</li><li>Introduction to IN, NOT IN, LIKE Opeaters</li></ol>'
                }
            },
            criteria: {
                qualification:
                    'BE, B.TECH, BCA, BCS, B.SC, MCA, MCS, MSC Also any graduate who can understand the English Language',
                hardware: 'A laptop, Good & stable internet connection',
                communication: 'Basic English - speaking, reading and writing',
                mental: 'You should learn the fundamentals of programming, which is just what you needed as a first step toward your career change',
            },
            what_to_learn: {
                course: [
                    'assets/img/course-details/html.svg',
                    'assets/img/course-details/css.svg',
                    'assets/img/course-details/bootstrap.png',
                    'assets/img/course-details/javascript.svg',
                    'assets/img/course-details/typescript.png',
                    'assets/img/course-details/angular.png',
                ],
                session_one: 'HTML, CSS & DOM, Bootstrap',
                session_two:
                    'Programming Basics in JavaScript, Implementation, Brute-force, String, Array, Loops, Key-Value Pairs, Time & Space Complexity, Sorting Algorithms and Typescript basic',
                session_three:
                    'Typescript Advanced and Angular Basics, Angular Advanced, Frontend Project, Servers, Project',
                session_four: 'Real Time Project',
            },
        },
        {
            id: '6',
            url: 'dot-net-full-stack',
            image: 'assets/img/courses/dot_net_core.png',
            logo1:'assets/img/courses/angularTech/csharp.png',
            logo2:'assets/img/courses/angularTech/github.png',
            logo3:'assets/img/courses/angularTech/sql.png',
            logo4:'assets/img/courses/angularTech/jira.png',
            logo5:'assets/img/courses/angularTech/jenkins.png',
            logo6:'assets/img/courses/angularTech/agile.png',
            duration:'Duration 5 months',
            course_banner: 'assets/img/course-details/dotnet-banner.png',
            title: 'DOT NET FULL STACK',
            desc: '.NET is an open-source platform for building desktop, web, and mobile applications that can run natively on any operating system. The .NET system includes tools, libraries, and languages that support modern, scalable, and high-performance software development. An active developer community maintains and supports the .NET platform.',
            view: 'View More',
            syllabus: 'assets/syllabus/Syllabus - Complete Dot Net.pdf',
            fee: '20K',
            points: '<ol><li> C# .Net : basic OOPS Concepts </li><li> GIT+GitHub : SCM Tool</li><li> Solid Principles :  Design Patterns </li><li> Agile+Jira : Scrum Issue tracking </li><li> SQL : Database</li><li>.NET Core+Web API</li></ol>',
            trainer: {
                name: 'Mr.Dhananjay M',
                image: 'assets/img/all-course-icon.png',
                current_position: 'Project Manager',
                desc: 'Currently working as a Project Manager in FIS Global<br/> Overall 11 plus years of experience in Web and Product Development - .Net full stack Developement<br/> Also currently managing team of 20 associates and working closely with clients for deliverables.<br/> Prior to that had working experience with Fiserv, Infosys and HSBC<br/> Skill Set : C# .Net, ASP .Net, MVC, WCF, .Net Core, Web API, Angular.',
            },
            course: {
                desc: 'For a duration of 15 weeks, the course requires a commitment from 7AM - 8:30AM on weekdays, and 11AM-5PM on Saturday in an intensive and immersive curriculum.',
                duration: '15 Week (4 Months)',
                time: '7AM - 8:30AM week days<br> 11AM - 5PM Saturday',
                placement: '90.99% Pleacements',
                namehtml:{
                type:'C# .NET',
                    subpoints:'<ol><li>OOPs-Concept & MS.NET Framework Introductio 1 Weeks</li><li>C# syntax</li><li>Collections and Generics & Exception Handling</li><li>Exception Handling </li><li>IO Streams</li><li>Delegates & Events & Multithreading</li><li>Multithreading</li></ol>'
                    },
                   namecss:{
                     type:'SQL',
                     subpoints:'<ol><li>Basics</li><li>DML Statements</li><li>SQL Functions</li><li>Select Queries</li><li>SQL Joins</li><li>SQL Operators</li><li>Store Procedure</li><li>Functions</li></ol>'
                    },
                    namejavascript:{
                        type:'ASP.NET',
                        subpoints:'<ol><li>NET Core Overview</li><li>Install ASP.NET Core</li><li>Create ASP.NET Core Web Application</li><li>ASP.NET Core Projects Folder Structure</li><li>String</li><li>wwwroot Folder</li><li>Importance of Startup.cs File</li><li>Working with Command-line Interface</li><li>ASP.NET Core Environment Variables</li><li>Dependency Injections</li><li>Built-in IoC Container</li><li>Working with Middleware</li><li>Custom Middlewares</li><li>Serving Static Files in ASP.NET Core</li><li>Serving Static Files From Any Folder in ASP.NET Core</li><li>Exception Handling in ASP.NET Core</li><li></li>Logging in .NET Core</ol><<li>Logging in ASP.NET Core</li><li>.NET Core Application Types</li>'
                    },
                    nameangular:{
                        type:'DATABASE',
                        subpoints:'<ol><li>Introduction to Database</li><li>What is SQL & SQL Commands</li><li>Datatypes in SQL</li><li>How to Create table in Database</li><li>How to Insert record into Database</li><li>Introduction to Select, Update and Delete statement</li><li>Introduction to Primary Key, Foreign Key, Composite Key</li><li>Introduction to AND, OR and NOT Operater</li><li>Introduction to SQL Functions</li><li>Introduction to Joins in SQL & Types</li><li>Introduction to IN, NOT IN, LIKE Opeaters</li></ol>'
                    }
            },
            criteria: {
                qualification:
                    'BE, B.TECH, BCA, BCS, B.SC, MCA, MCS, MSC Also any graduate who can understand the English Language',
                hardware: 'A laptop, Good & stable internet connection',
                communication: 'Basic English - speaking, reading and writing',
                mental: 'You should learn the fundamentals of programming, which is just what you needed as a first step toward your career change',
            },
            what_to_learn: {
                course: [
                    'assets/img/course-details/html.svg',
                    'assets/img/course-details/css.svg',
                    'assets/img/course-details/bootstrap.png',
                    'assets/img/course-details/javascript.svg',
                    'assets/img/course-details/typescript.png',
                    'assets/img/course-details/angular.png',
                ],
                session_one: 'HTML, CSS & DOM, Bootstrap',
                session_two:
                    'Programming Basics in JavaScript, Implementation, Brute-force, String, Array, Loops, Key-Value Pairs, Time & Space Complexity, Sorting Algorithms and Typescript basic',
                session_three:
                    'Typescript Advanced and Angular Basics, Angular Advanced, Frontend Project, Servers, Project',
                session_four: 'Real Time Project',
            },
        },
        {
            id: '7',
            url: 'devops',
            image: 'assets/img/courses/devops.png',
            logo1:'assets/img/courses/angularTech/devops.png',
            logo2:'assets/img/courses/angularTech/bash.png',
            logo3:'assets/img/courses/angularTech/docker.png',
            logo4:'assets/img/courses/angularTech/Kubernetes.png',
            logo5:'assets/img/courses/angularTech/jenkins.png',
            logo6:'assets/img/courses/angularTech/ubntu.png',
            duration:'Duration 5 months',
            course_banner: 'assets/img/course-details/devops-banner.png',
            title: 'DEVOPS',
            desc: 'DevOps influences the application lifecycle throughout its plan, develop, deliver, and operate phases. Each phase relies on the others, and the phases are not role-specific. In a true DevOps culture, each role is involved in each phase to some extent.',
            view: 'View More',
            syllabus: 'assets/syllabus/DevOps Course Content.pdf',
            fee: '30K',
            points: '<ol><li>Intro of DevOps : SDLC + Agile </li><li>Linux & Virtual Box : Centos 7 + Ubuntu | Bash Scripting : Programming Fundamentals</li><li> GIT : SCM Tool </li><li> Docker : installation + Advance</li><li>Kubernetes : Intro + Cluster + Pods</li> <li>AWS : Cloud + Services</li> <li>CICD : Jenkins + Development</li></ol>',
            trainer: {
                name: 'Mr.Dhanaji M',
                image: 'assets/img/all-course-icon.png',
                current_position: 'Senior Software Engineer',
                desc: 'Currently working as a Senior Software Engineer in Wipro<br/> Overall 8 plus years of experience in Web and Product Development - .Net Technologies<br/> Prior to that had working experience with Capita and LNT Infotech<br/> Skill Set : Angular, Bootstrap, C# .Net, ASP .Net, .Net Core, Web API',
            },
            course: {
                desc: 'For a duration of 15 weeks, the course requires a commitment from 7AM - 8:30AM on weekdays, and 11AM-5PM on Saturday in an intensive and immersive curriculum.',
                duration: '15 Week (4 Months)',
                time: '7AM - 8:30AM week days<br> 11AM - 5PM Saturday',
                placement: 'will update soon...',
            },
            criteria: {
                qualification:
                    'BE, B.TECH, BCA, BCS, B.SC, MCA, MCS, MSC Also any graduate who can understand the English Language',
                hardware: 'A laptop, Good & stable internet connection',
                communication: 'Basic English - speaking, reading and writing',
                mental: 'You should learn the fundamentals of programming, which is just what you needed as a first step toward your career change',
            },
            what_to_learn: {
                course: [
                    'assets/img/course-details/html.svg',
                    'assets/img/course-details/css.svg',
                    'assets/img/course-details/bootstrap.png',
                    'assets/img/course-details/javascript.svg',
                    'assets/img/course-details/typescript.png',
                    'assets/img/course-details/angular.png',
                ],
                session_one: 'HTML, CSS & DOM, Bootstrap',
                session_two:
                    'Programming Basics in JavaScript, Implementation, Brute-force, String, Array, Loops, Key-Value Pairs, Time & Space Complexity, Sorting Algorithms and Typescript basic',
                session_three:
                    'Typescript Advanced and Angular Basics, Angular Advanced, Frontend Project, Servers, Project',
                session_four: 'Real Time Project',
            },
        },
    ];

    promptShow = new BehaviorSubject<boolean>(true);
    constructor() {}

    searhedCourse(title: string) {
        return this.allCourses.find((x) => x.title == title);
    }
}
