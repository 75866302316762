import {
    DOCUMENT,
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from '@angular/common';
import { Component, ElementRef, Inject, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
    ActivatedRoute,
    NavigationCancel,
    NavigationEnd,
    Router,
    RouterState,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { CoursesService } from './services/courses.service';
import { LinkService } from './services/link.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class AppComponent {
    location: any;
    routerSubscription: any;
    showPrompt: any;
   
    constructor(
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        private titleService: Title,
        private courseService: CoursesService,
        private renderer: Renderer2,
        private el: ElementRef,
        private linkService: LinkService,
      
    ) {
        // this.router.events.subscribe((event) => {
        //     if (event instanceof NavigationEnd) {
        //       document.body.scrollTop = 0;
        //       document.documentElement.scrollTop = 0;
        //     }
        //   });
        this.handleRouteEvents();
        
    }

    ngOnInit() {
        this.recallJsFuntions();
        // this.showPrompt = this.courseService.promptShow;
        

        // this.linkService.popupStatus$.subscribe((isOpen) => {
        //     if (isOpen) {
        //       this.renderer.addClass(document.body, 'blur-filter');
        //     } else {
        //       this.renderer.removeClass(document.body, 'blur-filter');
        //     }
        //   });

    }
 //add new new method
   
    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe((event) => {
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
                // document.body.scrollTop = 0;
                // document.documentElement.scrollTop = 0;
            });
    }
   

    // handleRouteEvents() {
    //     this.router.events.subscribe((event) => {
    //         if (event instanceof NavigationEnd) {
    //             const title = this.getTitle(
    //                 this.router.routerState,
    //                 this.router.routerState.root
    //             ).join('-');
    //             this.titleService.setTitle(title);
    //             gtag('event', 'page_view', {
    //                 page_title: title,
    //                 page_path: event.urlAfterRedirects,
    //                 page_location: this.document.location.href,
    //             });
    //         }
    //     });
    // }
    handleRouteEvents() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const title = this.getTitle(
                    this.router.routerState,
                    this.router.routerState.root
                ).join('-');
                this.titleService.setTitle(title);
                gtag('event', 'page_view', {
                    page_title: title,
                    page_path: event.urlAfterRedirects,
                    page_location: this.document.location.href,
                });
    
                // Scroll to the top of the page
                // window.scrollTo(0, 0);
                // 
                // this.document.body.scrollTop = 0;
                // this.document.documentElement.scrollTop = 0;
            //     this.renderer.setProperty(this.document.body, 'scrollTop', 0);
            // this.renderer.setProperty(this.document.documentElement, 'scrollTop', 0);
            }
        });
    }

    getTitle(state: RouterState, parent: ActivatedRoute): string[] {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
            data.push(parent.snapshot.data['title']);
        }
        if (state && parent && parent.firstChild) {
            data.push(...this.getTitle(state, parent.firstChild));
        }
        return data;
    }

    closePrompt() {
        this.showPrompt.next(false);
        console.log(this.courseService.promptShow.next(false));
    }
    // Active(){
    //     window.scroll(0,0);
    // }
}
