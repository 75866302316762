<div class="course-area bg-fef8ef ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="course-image">
                    <div class="row">
                        <img [src]="course?.image" [alt]="course?.title" />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="course-content">
                    <span class="sub-title">Course Details</span>
                    <h2 [innerHTML]="course?.title"></h2>
                    <p [innerHTML]="course?.desc"></p>
                    <ul class="features-list">
                        <li>
                            <span><i class="flaticon-experience"></i> Expert
                                Trainers</span>
                        </li>
                        <li>
                            <span><i class="flaticon-time-left"></i> Lifetime
                                Acces</span>
                        </li>
                        <li>
                            <span><i class="flaticon-tutorials"></i> Remote
                                Learning</span>
                        </li>
                        <li>
                            <span><i class="flaticon-self-growth"></i> Self
                                Development</span>
                        </li>
                    </ul>
                    <a (click)="downloadPDF(course?.syllabus)" class="download">Download Syllabus</a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image" /></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image" /></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image" /></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image" /></div>
</div>