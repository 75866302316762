<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <img src="assets/img/codemind-banner.png" alt="codemind-banner" />
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contact</li>
            </ul>
            <h2>Contact</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div> -->
<div class="contact-page-top">

</div>

<div class="contact-area ptb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="contact-info">
          <p>
            Connect with <b>
              <h2>CODEMIND Technology</h2>
            </b> For personalized guidance and support on your educational journey.
            Reach us by phone or email to discuss your goals and discover how our programs can lead you to success.
          </p>
          <div class="col-xl-6 col-lg-5  wow fadeInUp" data-wow-delay=".4s"
            style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;">
            <div class="contact__list-item">

              <div class="contact__list-items">
                <div class="contact__list-item-icon">

                </div>
                <h3>India</h3>

                <div class="contact__list-items-icons">
                  <i class="bx bx-map"></i>
                </div>
                <div class="contact__list-items-text">
                  <a href="#">403,Ashtvinayak, Vinayak Colony,Kirti Classic, Luxmi Chowk,Near Rajiv Gandhi Infotech
                    Park, Hinjewadi,<br> Pune-411058.</a>
                </div>
                <div class="contact__list-items-icons">
                  <i class="bx bx-phone-call"></i>
                </div>
                <div class="contact__list-items-text">
                  <a href="#">+91-9665 0445 98 <br> +91-9665 0446 98</a>
                </div>

                <div class="contact__list-items-icons">
                  <i class="bx bx-envelope"></i>
                </div>
                <div class="contact__list-items-text">
                  <a href="mailto:hello@odemy.com">info@codemindtechnology.com</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 ">
        <div class="login-16 mt-5">
          <div class="align-self-center pad-0 login-box">
            <div class="form-section align-self-center">
              <h2 id="title"> <b> "Unlock Your Potential Today!"</b> </h2>
              <form [formGroup]="firstFormGroup">
                <div class="form-group clearfix">
                  <span class="icons">
                    <img src="../../../assets/img/user.png" alt="">
                  </span>
                  <div class="form-box">
                    <input formControlName="fullName" class="form-control" placeholder="Full Name" type="text"
                      required />
                  </div>
                </div>
                <div *ngIf="firstFormGroup.controls['fullName'].touched && firstFormGroup.controls['fullName'].invalid"
                  class="text-danger text-bold Validation-mes">Full Name is required*</div>
                <div class="form-group clearfix">
                  <span class="icons">
                    <img src="../../../assets/img/whatapp.png" alt="">
                  </span>
                  <div class="form-box">
                    <input formControlName="mobile" class="form-control" placeholder="Mobile" type="number" required />
                  </div>
                </div>
                <div *ngIf="firstFormGroup.controls['mobile'].touched && firstFormGroup.controls['mobile'].invalid"
                  class="text-danger text-bold Validation-mes">Mobile Number is required*
                </div>
                <div class="form-group clearfix">
                  <span class="icons">
                    <img src="../../../assets/img/gmail.png" alt="">
                  </span>
                  <div class="form-box">
                    <input class="form-control" formControlName="email" placeholder="Email" type="email" required />
                  </div>
                </div>
                <div>
                  <div *ngIf="firstFormGroup.controls['email'].touched && firstFormGroup.controls['email'].invalid"
                    class="text-danger text-bold Validation-mes">Valid email address is required*</div>
                </div>
                <div class="form-group clearfix">
                  <span class="icons">
                    <img id="education" src="../../../assets/img/education_3976631.png" alt="">
                  </span>
                  <div class="form-box" id="courses">
                    <mat-select formControlName="courses" required placeholder="Courses" class="form-control">
                      <mat-option value="Angular">Angular</mat-option>
                      <mat-option value="React">React</mat-option>
                      <mat-option value="Java">Java</mat-option>
                      <mat-option value="Dot Net">Dot Net</mat-option>
                      <mat-option value="Manual and Automation Testing">Manual and Automation Testing</mat-option>
                      <mat-option value="Python/Data Science">Python/Data Science</mat-option>
                      <mat-option value="DevOps">DevOps</mat-option>
                      <mat-option value="Data Science">Data Science</mat-option>
                    </mat-select>
                  </div>
                </div>
                <span *ngIf="firstFormGroup.controls['courses'].touched && firstFormGroup.controls['courses'].invalid"
                  class="text-danger text-bold ms-1 Validation-mes">Please Select Course*</span>
                <div class="form-group clearfix">
                  <button type="submit" class="btn btn-primary btn-lg btn-theme w-25"
                    [disabled]="firstFormGroup.invalid" (click)="getContactUsFormValue()">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="map">
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1205.580502935193!2d73.73596725806266!3d18.60508493527469!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bb485e201b0b%3A0x22c81ae192c7ca15!2sAshthvinayak%20Apartment!5e0!3m2!1sen!2sin!4v1671027939563!5m2!1sen!2sin"
    width="600" height="450" style="border: 0" allowfullscreen="" loading="lazy"
    referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>