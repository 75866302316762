<div class="advisor-area bg-f9f9f9 pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Technical Expertisee</span>
        </div>
        <div class="advisor-slides">
            <owl-carousel-o [options]="advisorSlides">
                <ng-template carouselSlide *ngFor="let adv of courseAdvisor">
                    <div class="single-advisor-box" [ngStyle]="{'background-color': randomColor}">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-md-12">
                                <div class="advisor-image">
                                    <img (click)="toProfile(adv)" [src]="adv?.image" [alt]="adv?.name" width="20%" />
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <div class="advisor-content">
                                    <h3>
                                        <a (click)="toProfile(adv)"  [innerHTML]="adv?.name"></a>
                                    </h3>
                                    <!-- <span
                                        class="sub-title"
                                        [innerHTML]="adv?.expertisee"
                                    ></span> -->
                                    <p  [innerHTML]="adv?.desc"></p>
                                    <ul class="social-link">
                                        <li *ngIf="adv && adv?.facebook_link">
                                            <a [href]="adv?.facebook_link" class="d-block" target="_blank">
                                                <i class="bx bxl-facebook"></i></a>
                                        </li>
                                        <li *ngIf="adv && adv?.instagram_link">
                                            <a [href]="adv?.instagram_link" class="d-block" target="_blank">
                                                <i class="bx bxl-instagram"></i></a>
                                        </li>
                                        <li *ngIf="adv && adv?.linkedIn_link">
                                            <a [href]="adv?.linkedIn_link" class="d-block" target="_blank"><i
                                                    class="bx bxl-linkedin"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>