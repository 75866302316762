import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ContactService } from 'src/app/services/contact.service';


@Component({
  selector: 'app-book-demo',
  templateUrl: './book-demo.component.html',
  styleUrls: ['./book-demo.component.scss']
})
export class BookDemoComponent implements OnInit {

  firstFormGroup!: FormGroup;
  @Output() closeDialog = new EventEmitter<void>();
  constructor(
    private _formBuilder: FormBuilder,
    private contactService: ContactService,
    public dialogRef: MatDialogRef<BookDemoComponent>,
  ) { }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      fullName: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required, Validators.maxLength(10)]),
      email: new FormControl('', [Validators.required, Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      ]),
      courses: new FormControl('', [Validators.required]),
    });

  }

  /* getContactUsFormValue() {
     this.contactService.sendFormData(this.firstFormGroup.value)
       .subscribe(
         response => {
           console.log('API Response:', response);
           alert('Form submitted successfully!');
         },
         error => {
           console.error('API Error:', error);
           alert('Failed to submit form. Please try again.');
           this.firstFormGroup.reset();
         }
       );
   
     
   }*/

  getContactUsFormValue() {
    const formValue = this.firstFormGroup.value;

    const apiPayload = {
      BranchKey: "E39C1D2D-B060-40BD-A181-0AC00D5F96E2",
      StudentName: formValue.fullName,
      Mobile: formValue.mobile,
      Email: formValue.email,
      GradeName: formValue.courses,
      GradeId: this.getGradeIdByName(formValue.courses),

    };
    console.log('API Payload:', apiPayload);

    this.contactService.sendFormData(apiPayload)
      .subscribe(
        response => {
          console.log('API Response:', response);
          alert('Form submitted successfully!');
          this.firstFormGroup.reset();
          this.dialogRef.close();
        },
        error => {
          console.error('API Error:', error);
          alert('Failed to submit form. Please try again.');

        }
      );

  }


  onCloseClick(): void {
    this.closeDialog.emit();
    this.dialogRef.close();

  }

  getGradeIdByName(gradeName: string): number {

    const grades = [
      { id: 58021, name: 'Angular' },
      { id: 59865, name: 'Campus connect' },
      { id: 59158, name: 'Data Science' },
      { id: 59027, name: 'DevOps' },
      { id: 59026, name: 'Dot Net' },
      { id: 57984, name: 'Java' },
      { id: 58023, name: 'Manual And Automation Testing' },
      { id: 58022, name: 'Python/Data Science' },
      { id: 58024, name: 'React' },


    ];

    const selectedGrade = grades.find(grade => grade.name === gradeName);
    return selectedGrade ? selectedGrade.id : 0; // Return 0 if not found, adjust accordingly
  }

}
