<section id="silck">
    <div class="container">
        <div class="section-title">
            <h2>Placed Student reviews​</h2>
        </div>
        <div class="feedback-slides-two">
            <owl-carousel-o [options]="candidateSlides">
                <ng-template carouselSlide *ngFor="let candidate of  CandidateReview; let i = index">
                    <div class="single-features-box">
                        <div class="icon">
                            <img class="candidate-img" [src]="candidate?.image" />
                        </div>
                        <b><small [innerHTML]="candidate?.name"></small></b><br />
                        <div class="review-rating">
                            <div class="review-stars">
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star checked"></i>
                                <i class="bx bxs-star checked"></i>
                            </div>
                        </div>
                        <div class="PlacementCard_line"></div>
                        <div class="review">
                        <b class="text-dark" [innerHTML]="candidate?.review"></b>
                    </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>