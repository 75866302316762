<div class="funfacts-and-feedback-area ptb-100">
    <h1 class="text-center heading">If You Have Question About <span class="code">CODE</span>MIND Technology <br>You Are
        In The Right Place</h1>
    <div class="container">
        <img src="assets/img/FAQ 1.png">
        <!--<div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-content">
                    <span class="sub-title">Distance learning</span>
                    <h2>
                        Flexible Study at Your Own Pace, According to Your Own
                        Needs
                    </h2>
                    <p>
                        <strong>CODEMIND Technology</strong> offers a flexible study program that allows individuals to
                        learn at
                        their own pace and according to their specific needs. The program is personalized, self-paced,
                        and employs a blended learning approach with online resources and hands-on projects. Expert
                        guidance is provided throughout the learning journey, and practical application of skills is
                        emphasized. Additionally, the program offers a flexible schedule to accommodate various
                        commitments and preferences.
                    </p>
                    <div class="feedback-slides">
                        <owl-carousel-o [options]="feedbackSlides">
                            <ng-template carouselSlide *ngFor="let developer of feedbackByDeveloper">
                                <div class="single-feedback-item">
                                    <p [innerHTML]="developer?.quote"></p>
                                    <div class="client-info d-flex align-items-center">
                                        <img [src]="developer?.image" class="rounded-circle" [alt]="developer?.name" />
                                        <div class="title">
                                            <h3 [innerHTML]="developer?.name"></h3>
                                            <span>
                                                <b [innerHTML]="
                                                        developer?.company
                                                    "></b> :
                                                <b [innerHTML]="
                                                        developer?.designation
                                                    "></b>
                                            </span>
                                            <span [innerHTML]="developer?.course"></span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="funfacts-list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box" style="background-color: #0f8cff;">
                                <h3><span [countUp]="1707">00</span></h3>
                                <p>Finished Sessions</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box" style="background-color: #ea576f">
                                <h3><span [countUp]="1109">00</span></h3>
                                <p>Enrolled Learners</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box" style="background-color: #5cdb57">
                                <h3><span [countUp]="17">00</span></h3>
                                <p>Online Instructors</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box" style="background-color: #fca733;">
                                <h3><span [countUp]="100">00</span>%</h3>
                                <p>Satisfaction Rate</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     <div class="shape2"><img src="assets/img/shape2.png" alt="image" /></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image" /></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image" /></div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div> -->

        <div class="courses-details-desc">
            <ngx-tabset>
                <ngx-tab tabTitle="Course">
                    <div class="courses-overview">
                        <mat-accordion>
                            <mat-expansion-panel *ngFor="let frequents of FrequentQuestion"
                                (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <p [innerHTML]="frequents?.question" class="question"></p>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p [innerHTML]="frequents?.Ans" class="ans"></p>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Eligibility">
                    <mat-accordion>
                        <mat-expansion-panel *ngFor="let eli of  eliglible" (opened)="panelOpenState = true"
                            (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p [innerHTML]="eli?. question" class="question"></p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p [innerHTML]="eli?.Ans" class="ans"></p>
                        </mat-expansion-panel>
                    </mat-accordion>
                </ngx-tab>
                <ngx-tab tabTitle="Fees">
                    <mat-accordion>
                        <mat-expansion-panel *ngFor="let fee of Fees" (opened)="panelOpenState = true"
                            (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p [innerHTML]="fee?. question" class="question"></p>
                                </mat-panel-title>

                            </mat-expansion-panel-header>
                            <p [innerHTML]="fee?.Ans" class="ans"></p>
                        </mat-expansion-panel>
                    </mat-accordion>
                </ngx-tab>
            </ngx-tabset>
        </div>
    </div>
</div>