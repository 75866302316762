<!--<div class="main-banner">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-content">
                    <div [align]="'center'">
                        <h1>
                            <span *ngFor="let letter of animText" [innerHTML]="letter"></span>
                        </h1>
                    </div>
                    <div [align]="'center'">
                        <strong>Your Success Partner</strong>
                    </div>

                    <p class="text-justify mt-4">
                        CODEMIND TECHNOLOGY is a leading Training and Placement
                        Academy, which is now expanding in more technical
                        trainings and in software development. CodeMind is well
                        known for its Quality Training & good placement track
                        for fresher’s as well as working professional.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12" [align]="'center'">-->
                <!-- <owl-carousel-o [options]="courseSlides">
                    <ng-template carouselSlide>
                        <div class="col-lg-3 col-sm-6 col-md-6 slide" [ngStyle]="{'background-color': randomColor}">
                            <div class="single-features-box">
                                <div class="icon">
                                    <img class="all-course-icon" src="assets/img/all-course-icon.png" />
                                </div>
                                <h3 class="text-dark">Our Courses</h3>
                                <ul *ngFor="let course of customCourses">
                                    <li class="course-name text-dark">
                                        {{ course?.title | titlecase }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide *ngFor="let course of customCourses">
                        <div class="col-lg-3 col-sm-6 col-md-6 slide">
                            <div class="single-features-box" [ngStyle]="{'background-color': randomColor}">
                                <div class="icon">
                                    <img class="course-img" [src]="course?.image" [alt]="course?.title" />
                                </div>
                                <h3 class="text-dark" [innerHTML]="course?.title"></h3>
                                <p class="text-dark" [innerHTML]="course?.points"></p>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o> -->
               <!-- <div class="banner-shape1">
                    <img src="assets/img/banner-shape1.png" alt="image" />
                </div>-->
                <!-- <div class="banner-shape2">
                    <img src="assets/img/banner-shape2.png" alt="image" />
                </div>
                <div class="banner-shape3">
                    <img src="assets/img/banner-shape3.png" alt="image" />
                </div> -->
           <!-- </div>
        </div>
    </div>
</div>-->
<owl-carousel-o [options]="courseSlides">
    <!--<ng-template carouselSlide>
        <div class="col-12">
            <img class="drive-img" src="assets/img/campus-drive/fabtech/fabtech-1.jpg"
                alt="campus drive at fabtech college sangola">
        </div>
    </ng-template>-->
    <!--<ng-template carouselSlide>
        <div class="col-12">
            <img class="drive-img" src="assets/img/campus-drive/sveri/sveri-1.jpg"
                alt="campus drive at SVERI college Pandharpur">
        </div>
    </ng-template>-->
    <ng-template carouselSlide>
        <div class="col-12">
            <img class="drive-img" src="assets/img/campus-drive/sveri/sveri-2.jpg"
                alt="campus drive at SVERI college Pandharpur">
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="col-12">
            <img class="drive-img" src="assets/img/campus-drive/sveri/sveri-3.jpg"
                alt="campus drive at SVERI college Pandharpur">
        </div>
    </ng-template>
    <!-- <ng-template carouselSlide>
        <div class="col-12">
            <img class="drive-img" src="assets/img/campus-drive/karmyogi/karmayogi-1.jpg"
                alt="campus drive at Karmyogi college Pandharpur">
        </div>
    </ng-template> -->
    <!--<ng-template carouselSlide>
        <div class="col-12">
            <img class="drive-img" src="assets/img/campus-drive/karmyogi/karmayogi-2.jpg"
                alt="campus drive at Karmyogi college Pandharpur">
        </div>
    </ng-template>-->
    <!--<ng-template carouselSlide>
        <div class="col-12">
            <img class="drive-img" src="assets/img/campus-drive/sinhgad/sinhgad-1.png"
                alt="campus drive at SKN college Pandharpur">
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="col-12">
            <img class="drive-img" src="assets/img/campus-drive/sinhgad/sinhgad-2.png"
                alt="campus drive at SKN college Pandharpur">
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="col-12">
            <img class="drive-img" src="assets/img/campus-drive/alard/alard-1.jpg"
                alt="campus drive at Alard college Pune">
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="col-12">
            <img class="drive-img" src="assets/img/campus-drive/a-g-patil/agpatil-0.png"
                alt="campus drive at A G Patil college Solapur">
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="col-12">
            <img class="drive-img" src="assets/img/campus-drive/a-g-patil/agpatil-1.png"
                alt="campus drive at A G Patil college Solapur">
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="col-12">
            <img class="drive-img" src="assets/img/campus-drive/a-g-patil/agpatil-2.png"
                alt="campus drive at A G Patil college Solapur">
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="col-12">
            <img class="drive-img" src="assets/img/campus-drive/a-g-patil/agpatil-3.png"
                alt="campus drive at A G Patil college Solapur">
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="col-12">
            <img class="drive-img" src="assets/img/campus-drive/a-g-patil/agpatil-4.png"
                alt="campus drive at A G Patil college Solapur">
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="col-12">
            <img class="drive-img" src="assets/img/campus-drive/a-g-patil/agpatil-5.png"
                alt="campus drive at A G Patil college Solapur">
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="col-12">
            <img class="drive-img" src="assets/img/campus-drive/a-g-patil/agpatil-6.png"
                alt="campus drive at A G Patil college Solapur">
        </div>
    </ng-template>
    <ng-template carouselSlide>
        <div class="col-12">
            <img class="drive-img" src="assets/img/campus-drive/a-g-patil/agpatil-7.png"
                alt="campus drive at A G Patil college Solapur">
        </div>
    </ng-template>-->
</owl-carousel-o>
<app-why-choose-us></app-why-choose-us>