<div class="book-demo">
  <div class="close__dialog">
    <span (click)="onCloseClick()">X</span>
  </div>
  <div class="login-16">
    <div class="align-self-center pad-0 login-box">
      <div class="form-section align-self-center">
        <h2>Book a <strong>Free Demo Class</strong></h2>
        <div>
          <p class="title">Experience the magic within - a free demo class awaits!</p>
        </div>
        <form [formGroup]="firstFormGroup">
          <div class="form-group clearfix">
            <span class="icons">
              <img src="../../../assets/img/user.png" alt="">
            </span>
            <div class="form-box">
              <input formControlName="fullName" class="form-control" placeholder="Full Name" type="text" required />
            </div>
          </div>
          <div *ngIf="firstFormGroup.controls['fullName'].touched && firstFormGroup.controls['fullName'].invalid"
            class="text-danger text-bold Validation-mes">Full Name is required*</div>
          <div class="form-group clearfix">
            <span class="icons">
              <img src="../../../assets/img/whatapp.png" alt="">
            </span>
            <div class="form-box">
              <input formControlName="mobile" class="form-control" placeholder="Mobile" type="number" required />
            </div>
          </div>
          <div *ngIf="firstFormGroup.controls['mobile'].touched && firstFormGroup.controls['mobile'].invalid"
            class="text-danger text-bold Validation-mes">Mobile Number is required*
          </div>
          <div class="form-group clearfix">
            <span class="icons">
              <img src="../../../assets/img/gmail.png" alt="">
            </span>
            <div class="form-box">
              <input class="form-control" formControlName="email" placeholder="Email" type="email" required />
            </div>
          </div>
          <div>
            <div *ngIf="firstFormGroup.controls['email'].touched && firstFormGroup.controls['email'].invalid"
              class="text-danger text-bold Validation-mes">Valid email address is required*</div>
          </div>
          <div class="form-group clearfix">
            <span class="icons">
              <img id="education" src="../../../assets/img/education_3976631.png" alt="">
            </span>
            <div class="form-box" id="courses">
              <mat-select formControlName="courses" required placeholder="Courses" class="form-control">
                <mat-option value="Angular">Angular</mat-option>
                <mat-option value="React">React</mat-option>
                <mat-option value="Java">Java</mat-option>
                <mat-option value="Dot Net">Dot Net</mat-option>
                <mat-option value="Manual and Automation Testing">Manual and Automation Testing</mat-option>
                <mat-option value="Python/Data Science">Python/Data Science</mat-option>
                <mat-option value="DevOps">DevOps</mat-option>
                <mat-option value="Data Science">Data Science</mat-option>
              </mat-select>
            </div>
          </div>
          <span *ngIf="firstFormGroup.controls['courses'].touched && firstFormGroup.controls['courses'].invalid"
            class="text-danger text-bold ms-1 Validation-mes">Please Select Course*</span>
          <div class="form-group clearfix">
            <button type="submit" class="btn btn-primary btn-lg btn-theme" [disabled]="firstFormGroup.invalid"
              (click)="getContactUsFormValue()">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>