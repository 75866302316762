import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FeedbackService } from 'src/app/services/feedback.service';
import { PlacementsService } from 'src/app/services/placements.service';
import {MatExpansionModule} from '@angular/material/expansion';
import { FaqService } from 'src/app/services/faq.service';
import { CoursesService } from 'src/app/services/courses.service';

@Component({
    selector: 'app-funfacts-feedback',
    templateUrl: './funfacts-feedback.component.html',
    styleUrls: ['./funfacts-feedback.component.scss'],
   
})
export class FunfactsFeedbackComponent implements OnInit {
    feedbackByDeveloper: any;
    panelOpenState = false;
    Frequent:any;
    
    constructor(
        public router: Router,
        private feedbackService: FeedbackService,
        private placementService: PlacementsService,
        private faq:FaqService,
       
    ) { }

    ngOnInit(): void {
        this.feedbackByDeveloper = this.feedbackService.feedback;
    }

    bgImage = [
        {
            img: 'assets/img/white-bg.jpg',
        },
    ];

   FrequentQuestion=[
    {
        question:'What will be the duration of course?',
        Ans:'Ideally, the course is completed in 4 to 5 months. We believe every student has a different grasping power, so it differs based on the students ability to grasp things.',
    },
    {
        question:'Do I receive a certificate upon completing the course?',
        Ans:'Yes, a training certification will be issued.'
    },
    {
        question:'My application has been accepted, but I would like to start the course at a later date. Is that possible?',
        Ans:'Yes, you can choose a suitable later date batch while making the payment. If a suitable date is not available, please write to us at info@codemindtechnology.com so that we can assist you.'
    },
    {
        question:'Are there any practical sessions included in this course?',
        Ans:'Absolutely! This course offers hands-on live sessions.'
    }, 
    {
        question:'What is the format of instruction for the placement-guaranteed courses?',
        Ans:'The placement guarantee course consists of video-based modules that you can complete one after another. The videos are pre-recorded, allowing you to watch them at any time of the day for your convenience. The course includes assignments, mock interviews, and projects that you will undergo as you learn.',
    },


]
 
  eliglible=[
    {
        question:'Who is eligible to apply for the placements guarantee course?',
        Ans:'The placement guarantee course is designed for pre-final and final year students, recent graduates, and young professionals aspiring to pursue a career in development.'
    },
    {
        question:'Can I apply if I am not graduating within the next 12 months?',
        Ans:'If committed to a full-stack development career and ready to invest effort, you can apply. Applications are evaluated based on quality.'
    },
    {
        question:'Having more than 3 years of experience in another field, am I eligible to apply for one of those courses?',
        Ans:'Yes, you can apply.'
    },
    
]

   Fees=[
    {
        question:'What are the different options available to pay the course fee?',
        Ans:'You can pay the Placement Guarantee course fee online using any of the payment methods: debit card, credit card, net banking. You will receive the link to make the payment once your application is successful.'
    },
    {
        question:'I am worried about the fees installment option. Is there any?',
        Ans:'Dont worry! We do have a flexible fees installment option'
    },
    {
        question:'I faced a problem while making the payment. What should I do?',
        Ans:'Please call us on +91-9695 0446 98 with details, and we would be happy to help.',
    }
   ]
} 


